import React, { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { makeStyles, useTheme } from '@mui/styles';
import { CustomTable, Loading } from '../../../components/elements';
import { connect } from 'react-redux';
import { ActionButton } from "../../../components/common";
import { isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import PlusIcon from "@mui/icons-material/Add";
import PDSDialog from '../../../components/common/PDSDialog';
import ActivityState from '../../../components/common/ActivityState';
import AdminState from '../../../components/common/AdminState';
import { UC0103, UC0104, UC0107 } from '../../../utils/constants';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 24,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    userCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.green,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    userCardDeactivate: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.disabledGray,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    root: {
        height: 54,
        backgroundColor: theme.palette.background.gray,
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        paddingTop: 6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    state: {
        display: 'flex',
    },
    row1: {
        padding: 16,
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    row3: {
        paddingBottom: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.background.green,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.background.red,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
    value2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
    content: {
        margin: 'auto',
        padding: theme.spacing(4,4,4,4),
    }
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function UserList(props) {
    const classes = useStyles();
    const { palette } = useTheme();
    const { t } = useTranslation();
    const {
        role,
        userUseCases,
        isLoading,
        handleUserDetail,
        table,
        handleActivate,
        confirmModalOpened,
        setConfirmModalOpened,
    } = props;

    const [userToActive, setUserToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const renderRoleAdminTable = (param) => {
        if (param && param.admin) {
            return <>{t('YES')}</>
        } else {
            return <>{t('NO')}</>
        }
    }

    const renderRoleName = (param) => {
        if (param && param.roles) {
            let roles = '';
            for (let i = 0; i < param.roles.length; i++) {
                if (roles === '') {
                    roles = param.roles[i].name;
                } else {
                    roles += ',' + param.roles[i].name;
                }
            }
            return <>{roles}</>
        }
    }

    const columns = [
        {
            title: t('STATE'),
            field: 'active',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => <ActivityState active={rowData?.active} />
        },
        {
            title: t('FIRST_NAME'),
            field: 'firstName',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.firstName
            )
        },
        {
            title: t('LAST_NAME'),
            field: 'lastName',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.lastName
            )
        },
        {
            title: t('LOGIN'),
            field: 'login',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.userName
            ),
        },
        {
            title: t('EMAIL'),
            field: 'email',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.email
            ),
        },
        {
            title: t('ROLE_ADMIN'),
            field: 'admin',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleAdminTable(rowData)
            ),
        },
        {
            title: t('ROLE'),
            field: 'role',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleName(rowData)
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0104, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleUserDetail(rowData, false, true) }}
                            disabled={!isComponentEnabledForUser(UC0104, userUseCases) || actionInProgress}
                            className={classes.green2}
                            action="detail"
                        />
                    }
                    {isComponentVisibleForUser(UC0103, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleUserDetail(rowData, false, false) }}
                            disabled={!isComponentEnabledForUser(UC0103, userUseCases) || actionInProgress}
                            className={classes.red2}
                            action="edit"
                        />
                    }
                    {isComponentVisibleForUser(UC0107, userUseCases) &&
                        <ActionButton
                            handleClick={() => {
                                setUserToActive(rowData);
                                setConfirmModalOpened(true);
                            }}
                            disabled={!isComponentEnabledForUser(UC0107, userUseCases) || actionInProgress}
                            className={classes.blue2}
                            action={rowData.active ? t('DEACTIVATE') : t('ACTIVATE')}
                        />
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (
                    <PDSDialog
                        open={confirmModalOpened}
                        title={`${userToActive.active ? t('USER_INACTIVATION') : t('USER_ACTIVATION')}`}
                        onClose={() => {
                            setConfirmModalOpened(false);
                            setUserToActive(null);
                        }}
                        size="md"
                        headerColor={userToActive.active ? palette.primary.red : palette.primary.blue}
                        height={450}
                        onSubmit={() => { handleActivate(userToActive); }}
                        actionType={userToActive.active ? 'userDeactivate' : 'roleActivate'}
                        showCloseButton={false}
                        showCheckIcon={false}
                    >

                        <div className={classes.content}>
                            {userToActive.active ? t('CONFIRM_USER_DEACTIVE', { userName: userToActive.userName }) : t('CONFIRM_USER_ACTIVE', { userName: userToActive.userName })}
                        </div>
                    </PDSDialog>
                )}
            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={role}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                /> :
                    role.map((userItem, index) => (
                        <div className={userItem.active ? classes.userCard : classes.userCardDeactivate} key={userItem.id}>
                            <Grid container className={classes.row1} >
                                <Grid item xs={1.5}>
                                    <ActivityState active={userItem?.active} />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <AdminState param={userItem} />
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid
                                    item xs={4}
                                    container
                                    direction="column"
                                    alignItems="flex-end"
                                    justify="flex-start"
                                >
                                    {isComponentVisibleForUser(UC0103, userUseCases) &&
                                        <Tooltip title={t('EDIT')}>
                                            <EditIcon
                                                fontSize='large'
                                                onClick={() => {
                                                    handleUserDetail(userItem, false, false)
                                                }}
                                                disabled={!isComponentEnabledForUser(UC0103, userUseCases)}
                                                style={{ fontSize: 30, marginTop: -6 }}
                                            />
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>

                            <div className={classes.row2}>
                                <div className={classes.title}>
                                    <Tooltip title={t('NAME')}>
                                        <Typography variant="h3" style={{fontWeight: 600}}>{(userItem.firstName && userItem.firstName.length > 0) || (userItem.lastName && userItem.lastName)
                                            ? (userItem.firstName && userItem.firstName.length > 0 ? userItem.firstName + ' ' : '') + (userItem.lastName && userItem.lastName.length > 0 ? userItem.lastName : '') : userItem.userName}</Typography>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className={classes.row3}>
                                <div className={classes.title}>
                                    <Tooltip title={t('EMAIL')}>
                                        <Typography variant="h6">{userItem.email}</Typography>
                                    </Tooltip>
                                </div>
                            </div>

                            <Grid container className={classes.root} >
                                <Grid item xs={6}>
                                    {isComponentVisibleForUser(UC0104, userUseCases) &&
                                        <Tooltip title={t('SHOW_USER_DETAIL')}>
                                            <InfoIcon
                                                fontSize='large'
                                                disabled={actionInProgress || !isComponentEnabledForUser(UC0104, userUseCases)}
                                                onClick={() => { handleUserDetail(userItem, false, true) }}
                                                style={{ fontSize: 30 }}
                                            />
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {isComponentVisibleForUser(UC0107, userUseCases) &&
                                        <Tooltip title={userItem.active ? t('SHOW_USER_DEACTIVE') : t('SHOW_USER_ACTIVE')}>
                                            {userItem.active === true ?
                                                <CancelIcon
                                                    fontSize='large'
                                                    disabled={actionInProgress || !isComponentEnabledForUser(UC0107, userUseCases)}
                                                    onClick={() => {
                                                        setUserToActive(userItem);
                                                        setConfirmModalOpened(true);
                                                    }}
                                                    style={{ fontSize: 30 }}
                                                /> :
                                                <PlusIcon
                                                    fontSize='large'
                                                    disabled={actionInProgress || !isComponentEnabledForUser(UC0107, userUseCases)}
                                                    onClick={() => {
                                                        setUserToActive(userItem);
                                                        setConfirmModalOpened(true);
                                                    }}
                                                    style={{ fontSize: 30 }}
                                                />
                                            }
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    ))
                :
                <Loading />
            }
        </div>
    );
}

UserList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserList);