import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { getCameraCode, getErrorMessage } from '../../../utils';
import ToolBar from './ToolBar';
import PerfectScrollbar from "react-perfect-scrollbar";
import CheckpointList from "./CheckpointList";
import { createCheckpoint, updateCheckpoint, updateCheckpointState, fetchAllCheckpoints } from "../../../redux/actions/checkpoints";
import { createCameraConfiguration, updateCameraConfiguration, testCameraConfiguration } from "../../../redux/actions/cameraConfiguration"
import CheckpointUpdate from '../../../components/modal/CheckpointUpdate';
import { STEP_FIVE, STEP_FOUR, STEP_ONE, STEP_SIX, STEP_THREE, STEP_TWO } from '../../../utils/constants';
import CheckpointSupervisionUpdate from '../../../components/modal/CheckpointSupervisionUpdate';


const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

function Checkpoint(props) {

    const {
        user,
        errors,
        userUseCases,
        checkpoints,
        checkpoint,
        isCreating,
        isUpdating,
        fetchAllCheckpoints, createCheckpoint, updateCheckpoint, updateCheckpointState,
        createCameraConfiguration, updateCameraConfiguration,
        testCameraConfiguration
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [isFetchedCheckpoints, setIsFetchedCheckpoints] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedCheckpointName, setSelectedCheckpointName] = useState('');
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [filteredCheckpoints, setFilteredCheckpoints] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [saving, setSaving] = useState(false);
    const [table, setTable] = useState(false);

    useEffect(() => {
        if (!isFetchedCheckpoints) {
            fetchAllCheckpoints().then(() => {
                setIsLoading(false);
                setIsFetchedCheckpoints(true);
            }).catch((err) => {
                setIsLoading(false);
                setIsFetchedCheckpoints(false);
            });
        }
    }, [fetchAllCheckpoints, isFetchedCheckpoints, isLoading]);

    const handleSelectedCheckpointName = (value) => {
        setSelectedCheckpointName(value.target.value);
    };

    useEffect(() => {
        const filCheckpoint = [];
        if (checkpoints && checkpoints.length > 0) {
            for (let i = 0; i < checkpoints.length; i++) {
                let matchCriteria = true;
                if (selectedCheckpointName && selectedCheckpointName.trim().length > 0) {
                    if (checkpoints[i].name) {
                        if (!(checkpoints[i].name.toLowerCase().includes(selectedCheckpointName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (matchCriteria) {
                    filCheckpoint.push(checkpoints[i]);
                }

                if (updateCheckpointConfigModel && updateCheckpointConfigModel.checkpoint && updateCheckpointConfigModel.checkpoint.checkpointId == checkpoints[i].checkpointId) {
                    // update modal window refresh data
                    setUpdateCheckpointConfigModel({ ...updateCheckpointConfigModel, checkpoint: checkpoints[i] });
                }
            }
            setFilteredCheckpoints(filCheckpoint);
        }
    }, [checkpoints, selectedCheckpointName,]);

    const closeCheckpointUpdate = () => {
        setError('');
        setSnackbarOpen(false);
        setUpdateCheckpointConfigModel({
            isNew: false,
            checkpoint: { lat: '50.05193', lng: '14.42686' },
            open: false,
            supervisionOpen: false,
        });
        setMode(STEP_ONE);
    }

    const handleCheckpointUpdate = (checkpoint, isNew) => {
        setError('');
        setSnackbarOpen(false);

        let newCheckpoint = { ...checkpoint };

        newCheckpoint.active = newCheckpoint.active === true ? 'true' : (newCheckpoint.active === false ? 'false' : '');
        newCheckpoint.admin = newCheckpoint.admin === true ? 'true' : (newCheckpoint.admin === false ? 'false' : '');

        setReadOnly(false);
        setUpdateCheckpointConfigModel({ isNew: isNew, checkpoint: newCheckpoint, open: true, supervisionOpen: false });
    };

    const handleCheckpointDetail = (checkpoint) => {
        setError('');
        setSnackbarOpen(false);
        let newCheckpoint = { ...checkpoint };
        newCheckpoint.active = newCheckpoint.active === true ? 'true' : (newCheckpoint.active === false ? 'false' : '');
        newCheckpoint.admin = newCheckpoint.admin === true ? 'true' : (newCheckpoint.admin === false ? 'false' : '');

        setReadOnly(true);
        setUpdateCheckpointConfigModel({ checkpoint: newCheckpoint, open: true, supervisionOpen: false });
    };

    const handleCheckpointSupervision = (checkpoint) => {
        setError('');
        setSnackbarOpen(false);

        setReadOnly(false);
        setUpdateCheckpointConfigModel({ checkpoint: checkpoint, open: false, supervisionOpen: true });
    };

    const handleActivate = (checkpoint) => {
        updateCheckpointState(checkpoint.checkpointId, !checkpoint.active).then(() => {
            setConfirmModalOpened(false);
            setIsFetchedCheckpoints(false);
        }).catch((err) => {
            console.log(err);
            setIsFetchedCheckpoints(false);
        });
    }

    const saveCheckpoint = (checkpoint) => {
        if (mode === STEP_ONE) {
            if (checkpoint && checkpoint.active === 'YES') checkpoint.active = true;
            if (checkpoint && checkpoint.active === 'NO') checkpoint.active = false;

            const action = (checkpoint && checkpoint.checkpointId) ? updateCheckpoint : createCheckpoint;
            action(checkpoint).then((prod) => {
                setIsLoading(true);
                setIsFetchedCheckpoints(false);
                setSaving(false);
                setNextStepAndClose(mode);
            }).catch((error) => {
                console.log('saveCenter error', error);
                setSnackbarOpen(true);
                setSaving(false);
                setError(getErrorMessage(error, t));
            });
        } else {
            let prevAction = Promise.resolve();
            if (mode === STEP_TWO) {
                // Save checkpoint configuration with the stop line
                if (checkpoint && checkpoint.active === 'YES') checkpoint.active = true;
                if (checkpoint && checkpoint.active === 'NO') checkpoint.active = false;

                const action = (checkpoint && checkpoint.checkpointId) ? updateCheckpoint : createCheckpoint;
                prevAction = action(checkpoint).then((prod) => {
                    setIsLoading(true);
                    setIsFetchedCheckpoints(false);
                    setSaving(false);
                    setNextStepAndClose(mode);
                }).catch((error) => {
                    console.log('saveCenter error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
            }

            let cameraConfigs = [];

            let cameraCodes = getCameraCode(mode);
            if (checkpoint.cameraConfiguration && checkpoint.cameraConfiguration.length > 0) {
                for (let i = 0; i < checkpoint.cameraConfiguration.length; i++) {
                    if (cameraCodes != null && cameraCodes.length > 0 && checkpoint.cameraConfiguration[i].cameraType === cameraCodes[0]) {
                        cameraConfigs.push(checkpoint.cameraConfiguration[i]);
                    }
                    if (cameraCodes != null && cameraCodes.length > 1 && checkpoint.cameraConfiguration[i].cameraType === cameraCodes[1]) {
                        cameraConfigs.push(checkpoint.cameraConfiguration[i]);
                    }
                }
            }

            if (cameraConfigs.length > 0) {
                let action = (cameraConfigs[0] && cameraConfigs[0].cameraConfigurationId) ? updateCameraConfiguration : createCameraConfiguration;
                let body = { ...cameraConfigs[0], checkpointId: checkpoint.checkpointId };
                prevAction.then(() => {
                    action(body).then((prod) => {
                        if (cameraConfigs.length > 1) {
                            action = (cameraConfigs[1] && cameraConfigs[1].cameraConfigurationId) ? updateCameraConfiguration : createCameraConfiguration;
                            body = { ...cameraConfigs[1], checkpointId: checkpoint.checkpointId };
                            action(body).then((prod) => {
                                setIsLoading(true);
                                setIsFetchedCheckpoints(false);
                                setSaving(false);
                                setNextStepAndClose(mode);
                            }).catch((error) => {
                                console.log('saveCameraConfig error', error);
                                setSnackbarOpen(true);
                                setSaving(false);
                                setError(getErrorMessage(error, t));
                            });
                        } else {
                            setIsLoading(true);
                            setIsFetchedCheckpoints(false);
                            setSaving(false);
                            setNextStepAndClose(mode);
                        }
                    }).catch((error) => {
                        console.log('saveCameraConfig error', error);
                        setSnackbarOpen(true);
                        setSaving(false);
                        setError(getErrorMessage(error, t));
                    })
                });
            }
        }
    };

    const setNextStepAndClose = (step) => {
        if (step === STEP_ONE) {
            setMode(STEP_TWO);
        } else if (step === STEP_TWO) {
            setMode(STEP_THREE);
        } else if (step === STEP_THREE) {
            setMode(STEP_FOUR);
        } else if (step === STEP_FOUR) {
            setMode(STEP_FIVE);
        } else if (step === STEP_FIVE) {
            setMode(STEP_SIX);
        } else if (step === STEP_SIX) {
            closeCheckpointUpdate();
        }
    };

    const [updateCheckpointConfigModel, setUpdateCheckpointConfigModel] = useState({
        open: false,
        checkpoint: { lat: '50.05193', lng: '14.42686' },
        isNew: true
    });

    useEffect(() => {
        if (checkpoint && !isCreating && !isUpdating && !errors &&
            updateCheckpointConfigModel.checkpoint.checkpointId === undefined) {
            setUpdateCheckpointConfigModel({
                ...updateCheckpointConfigModel,
                checkpoint: {
                    ...updateCheckpointConfigModel.checkpoint, checkpointId:
                        checkpoint.checkpointId
                }
            });
        }
    }, [checkpoint]);

    const [readOnly, setReadOnly] = useState(false);

    const [mode, setMode] = useState(STEP_ONE);

    const handleCameraTest = (camera, setCamera) => {
        testCameraConfiguration(camera).then((cameraPreview) => {
            setCamera(cameraPreview);
        }).catch((error) => {
            console.log('saveCenter error', error);
            setSnackbarOpen(true);
            setError(getErrorMessage(error, t));
        });
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>

                <ToolBar
                    userUseCases={userUseCases}
                    handleCheckpointUpdate={handleCheckpointUpdate}
                    selectedCheckpointName={selectedCheckpointName}
                    handleSelectedCheckpointName={handleSelectedCheckpointName}
                    table={table}
                    setTable={setTable}
                />
                <CheckpointList
                    refresh={() => {
                        setIsFetchedCheckpoints(false);
                    }}
                    save={saveCheckpoint}
                    userUseCases={userUseCases}
                    checkpoints={filteredCheckpoints}
                    handleCheckpointUpdate={handleCheckpointUpdate}
                    handleCheckpointDetail={handleCheckpointDetail}
                    handleActivate={handleActivate}
                    isLoading={isLoading}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={setError}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                    table={table}
                    setTable={setTable}
                    handleCheckpointSupervision={handleCheckpointSupervision}
                />

            </div>

            <CheckpointUpdate
                userUseCases={userUseCases}
                saveCheckpoint={saveCheckpoint}
                readOnly={readOnly}
                checkpointConfigModel={updateCheckpointConfigModel}
                handleClose={closeCheckpointUpdate}
                error={error}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                setError={setError}
                saving={saving}
                setSaving={setSaving}
                setMode={setMode}
                mode={mode}
                handleCameraTest={handleCameraTest}
            />

            <CheckpointSupervisionUpdate
                userUseCases={userUseCases}
                handleClose={closeCheckpointUpdate}
                checkpointConfigModel={updateCheckpointConfigModel}
                error={error}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
            />

        </PerfectScrollbar>
    );
}

Checkpoint.propTypes = {};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    checkpoints: store.checkpointsData.checkpoints,
    checkpoint: store.checkpointsData.checkpoint,
    isFetchedCheckpoints: store.checkpointsData.isFetchingAllCheckpoints,
    isCreating: store.checkpointsData.isCreating,
    isUpdating: store.checkpointsData.isUpdating,
    errors: store.checkpointsData.errors,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllCheckpoints,
    createCheckpoint,
    updateCheckpoint,
    updateCheckpointState,
    createCameraConfiguration,
    updateCameraConfiguration,
    testCameraConfiguration
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Checkpoint);
