import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {getErrorMessage} from '../../../utils';
import ToolBar from './ToolBar';
import PerfectScrollbar from "react-perfect-scrollbar";
import DistributionCenterList from "./DistributionCenterList";
import {createDistributionCenter, updateDistributionCenter, updateCenterState, fetchAllDistributionCenters} from "../../../redux/actions/distributionCenters";
import CenterUpdate from "../../../components/modal/CenterUpdate";


const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

function DistributionCenter(props) {

    const { 
        user,
        userUseCases,
        centers,
        fetchAllDistributionCenters,
        createDistributionCenter,
        updateDistributionCenter,
        updateCenterState,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [isFetchedCenters, setIsFetchedCenters] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedCenterName, setSelectedCenterName] = useState('');
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [filteredCenters, setFilteredCenters] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [saving, setSaving] = useState('');
    const [table, setTable] = useState(false);

    useEffect(() => {
        if (!isFetchedCenters) {
            fetchAllDistributionCenters().then(() => {
                setIsLoading(false);
                setIsFetchedCenters(true);
            }).catch((err) => {
                setIsLoading(false);
                setIsFetchedCenters(false);
            });
        }
    }, [fetchAllDistributionCenters, isFetchedCenters, isLoading]);

    const handleSelectedCenterName = (value) => {
        setSelectedCenterName(value.target.value);
    };

    useEffect(() => {
        const filCenter = [];
        if ( centers && centers.length>0 ) {
            for (let i = 0; i < centers.length; i++) {
                let matchCriteria = true;
                if (selectedCenterName && selectedCenterName.trim().length > 0) {
                    if (centers[i].name) {
                        if (!(centers[i].name.toLowerCase().includes(selectedCenterName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (matchCriteria) {
                    filCenter.push(centers[i]);
                }
            }
            setFilteredCenters(filCenter);
        }
    }, [centers, selectedCenterName,]);

    const closeCenterUpdate = () => {
        setError('');
        setSnackbarOpen(false);
        setUpdateCenterConfigModel({isNew: false, center: {lat: '50.05193', lng: '14.42686'}, open: false});
    }

    const handleCenterUpdate = (center, isNew) => {
        setError('');
        setSnackbarOpen(false);

        let newCenter = {...center};

        newCenter.active=newCenter.active===true?'true':(newCenter.active===false?'false':'');
        newCenter.admin=newCenter.admin===true?'true':(newCenter.admin===false?'false':'');

        setReadOnly(false);
        setUpdateCenterConfigModel({isNew: isNew, center: newCenter, open: true});
    };

    const handleCenterDetail = (center) => {
        setError('');
        setSnackbarOpen(false);
        let newCenter = {...center};
        newCenter.active=newCenter.active===true?'true':(newCenter.active===false?'false':'');
        newCenter.admin=newCenter.admin===true?'true':(newCenter.admin===false?'false':'');

        setReadOnly(true);
        setUpdateCenterConfigModel({center: newCenter, open: true});
    };

    const handleActivate = (center) => {
        updateCenterState(center.distributionCenterId, !center.active).then(() => {
            setConfirmModalOpened(false);
            setIsFetchedCenters(false);
        });
    }

    const saveCenter = (center) => {
        if ( center && center.active === 'YES') center.active=true;
        if ( center && center.active === 'NO') center.active=false;

        const action = (center && center.distributionCenterId) ? updateDistributionCenter : createDistributionCenter;
        action(center).then((prod) => {
            setIsLoading(true);
            setIsFetchedCenters(false);
            setSaving(false);
            closeCenterUpdate();
        }).catch((error) => {
            console.log('saveCenter error', error);
            setSnackbarOpen(true);
            setSaving(false);
            setError(getErrorMessage(error, t));
        });
    };

    const [updateCenterConfigModel, setUpdateCenterConfigModel] = useState({
        open: false,
        center: {lat: '50.05193', lng: '14.42686'},
        isNew: true
    });

    const [readOnly, setReadOnly] = useState(false);

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>

                <ToolBar
                    userUseCases={userUseCases}
                    handleCenterUpdate={handleCenterUpdate}
                    selectedCenterName={selectedCenterName}
                    handleSelectedCenterName={handleSelectedCenterName}
                    table={table}
                    setTable={setTable}
                />
                <DistributionCenterList
                    refresh={() => {
                        setIsFetchedCenters(false);
                    }}
                    save={saveCenter}
                    userUseCases={userUseCases}
                    centers={filteredCenters}
                    handleCenterUpdate={handleCenterUpdate}
                    handleCenterDetail={handleCenterDetail}
                    handleActivate={handleActivate}
                    isLoading={isLoading}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={setError}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                    table={table}
                    setTable={setTable}
                />

            </div>

            <CenterUpdate 
                userUseCases={userUseCases}
                saveCenter={saveCenter}
                readOnly={readOnly}
                centerConfigModel={updateCenterConfigModel}
                handleClose={closeCenterUpdate}
                error={error}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                setError={setError}
                saving={saving}
                setSaving={setSaving}
            />

        </PerfectScrollbar>
    );
}

DistributionCenter.propTypes = {};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    centers: store.centersData.centers,
    isFetchedCenters: store.centersData.isFetchedCenters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllDistributionCenters,
    createDistributionCenter,
    updateDistributionCenter,
    updateCenterState,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistributionCenter);
