import types from '../actionTypes';

const INITIAL_STATE = {
  isCreating: false,
  isUpdating: false,
  isTesting: false,
  cameraConfiguration: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CAMERACONFIGURATION_CREATE_REQUEST:
      return {
          ...state, 
          isCreating: true
      };
    case types.CAMERACONFIGURATION_CREATE_SUCCESS:
      return {
          ...state,
          isCreating: false
      };
    case types.CAMERACONFIGURATION_CREATE_FAIL:
      return {
          ...state, 
          isCreating: false,
          errors: action.payload.error,
      };

    case types.CAMERACONFIGURATION_UPDATE_REQUEST:
      return {
          ...state, 
          isUpdating: true
      };
    case types.CAMERACONFIGURATION_UPDATE_SUCCESS:
      return {
          ...state,
          isUpdating: false
      };
    case types.CAMERACONFIGURATION_UPDATE_FAIL:
      return {
          ...state, 
          isUpdating: false,
          errors: action.payload.error,
      };

    case types.CAMERACONFIGURATION_TEST_REQUEST:
      return {
          ...state, 
          isTesting: true
      };
    case types.CAMERACONFIGURATION_TEST_SUCCESS:
      return {
          ...state,
          isTesting: false
      };
    case types.CAMERACONFIGURATION_TEST_FAIL:
      return {
          ...state, 
          isTesting: false,
          errors: action.payload.error,
      };

    default:
      return state;
  }
};
