import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import { fetchAllUsers } from "../../../redux/actions/users";
import { fetchSupervisors, removeSupervision, addSupervision } from "../../../redux/actions/checkpoints";
import { CustomSearchInput } from '../../elements'
import { CircularProgress, Grid, Typography } from '@mui/material';
import SupervisionTable from './SupervisionTable';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            borderRadius: '20px',
        },
        height: '100%',
        // border: 'groove',
    },
    search: {
        paddingTop: '10px',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 800,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },

}));


const SupervisionSetting = ({
    supervisors,
    updatedCheckpoint,
    fetchSupervisors,
    allUsers,
    fetchAllUsers,
    addSupervision,
    removeSupervision,
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            const promises = [];

            // Fetch assigned supervisors if checkpointId is changed
            if (updatedCheckpoint.checkpointId &&
                updatedCheckpoint.checkpointId !== supervisors.checkpointId) {
                promises.push(fetchSupervisors(updatedCheckpoint.checkpointId));
            }

            // Fetch all users if not fetched yet
            if (allUsers.length === 0) {
                promises.push(fetchAllUsers());
            }

            Promise.all(promises)
                .then(() => setLoading(false))
                .catch((err) => {
                    console.log("Error", err);
                    setLoading(false);
                });
        }
    }, [updatedCheckpoint, supervisors]);

    const searchMenuData = !loading && allUsers
        ? allUsers
            .filter(data => `${data.firstName} ${data.lastName}`.includes(filterValue) || data.email.includes(filterValue))
            .map((data) => ({
                key: data.id,
                label: `${data.firstName} ${data.lastName}`,
                value: data.id,
            }))
        : [];

    const handleRemoveUserLocal = (value) => {
        setLoading(true);
        removeSupervision(value.id, updatedCheckpoint.checkpointId)
            .then(() => fetchSupervisors(updatedCheckpoint.checkpointId))
            .then(() => setLoading(false));
    };

    const searchMenuItemClickLocal = (userId) => {
        setLoading(true);
        addSupervision(userId, updatedCheckpoint.checkpointId)
            .then(() => fetchSupervisors(updatedCheckpoint.checkpointId))
            .then(() => setLoading(false));
    };

    return (
        <>
            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.search}>
                        <CustomSearchInput
                            placeholder={t('USER_NAME')}
                            menuData={searchMenuData}
                            menuItemClick={searchMenuItemClickLocal}
                            handleChange={setFilterValue}
                            showIcon={false}
                        />
                    </div>

                    {!loading && <SupervisionTable
                        supervisors={supervisors.supervisors}
                        handleRemoveUser={handleRemoveUserLocal}
                    />}
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogContent>
        </>
    );
}

SupervisionSetting.propTypes = {};
SupervisionSetting.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
    supervisors: store.checkpointsData.supervisors,
    allUsers: store.usersData.parkingUsers,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSupervisors,
    fetchAllUsers,
    addSupervision,
    removeSupervision,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SupervisionSetting);


