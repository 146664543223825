import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,
  isFetchingParkingAssignedUserList: false,
  isFetchingSubjectUsers: false,
  isAddingParkingToUser: false,
  isRemovingParkingFromUser: false,
  isFetchingRoleUser: false,
  isCreating: false,
  isUpdating: false,
  isPushRegistering: false,

  subjectUsers: [],
  parkingUsers: [],
  parkingAssignedUsers: [],
  parkingNotAssignedUsers: [],
  searchedUsers: [],
  roleUsers: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingUsers: action.payload.parkingUsers,
      };
    case types.USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };
    case types.USERS_BY_ROLE_FETCH_REQUEST:
      return {
        ...state,
        isFetchingRoleUser: true,
      };
    case types.USERS_BY_ROLE_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingRoleUser: false,
        roleUsers: action.payload.roleUsers,
      };
    case types.USERS_BY_ROLE_FETCH_FAIL:
      return {
        ...state,
        isFetchingRoleUser: false,
        errors: action.payload.error,
      };

    case types.USER_CREATE_SUCCESS:
      return {
        ...state, isCreating: false
      };
    case types.USER_CREATE_REQUEST:
      return {
        ...state, isCreating: true
      };
    case types.USER_CREATE_FAIL:
      return {
        ...state,
        isCreating: false,
        errors: action.payload.error,
      };

    case types.USER_UPDATE_SUCCESS:
      return {
        ...state, isUpdating: false
      };
    case types.USER_UPDATE_REQUEST:
      return {
        ...state, isUpdating: true
      };
    case types.USER_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload.error,
      };

    case types.USER_PUSH_REGISTRATION_SUCCESS:
      return {
        ...state, isPushRegistering: false
      };
    case types.USER_PUSH_REGISTRATION_REQUEST:
      return {
        ...state, isPushRegistering: true
      };
    case types.USER_PUSH_REGISTRATION_FAIL:
      return {
        ...state,
        isPushRegistering: false,
        errors: action.payload.error,
      };
      
    default:
      return state;
  }
};
