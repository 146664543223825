import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {Link} from "react-router-dom";
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0101, UC0111, UC0400, UC0410 } from '../../utils/constants';

import IconButton from '@mui/material/IconButton';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaceIcon from '@mui/icons-material/Place';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(3.75),
    },
    title: {
        cursor: 'pointer',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
    },
    item: {
        width: 370,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginRight: theme.spacing(2.5),
        marginTop: 0,
        backgroundColor: theme.palette.background.green,
        borderRadius: 15,
        cursor: 'pointer',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 160,
        marginTop: '20px'
        //margin: theme.spacing(2.5, 3.75, 3.75, 0),
    },
    itemToolBar: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        height: 20,
        width: '100%',
        // marginTop: '20px',
        paddingTop: '30px',
        paddingRight: '50px',
    },
    itemTitle: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.base[600],
    },
}));

function AdministrationList(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        userUseCases
    } = props;

    return (
        <div className={classes.container}>
            <div className={classes.list}>
                { isComponentVisibleForUser(UC0111, userUseCases) &&
                    <div className={classes.item}>
                        <Link to={isComponentEnabledForUser(UC0111, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/rolelist` : "#"}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <Typography variant="h4" style={{color: 'black', fontWeight: 600}}>{t('ROLE_ADMINISTRATION')}</Typography>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large" style={{paddingTop: '20px'}}>
                                        <SupervisorAccountIcon style={{ fontSize: '32px', color: 'black',  }} />
                                    </IconButton>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0101, userUseCases) &&
                    <div className={classes.item}>
                        <Link to={isComponentEnabledForUser(UC0101, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/userlist` : "#"}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <Typography variant="h4" style={{color: 'black', fontWeight: 600}}>{t('USER_ADMINISTRATION')}</Typography>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large" style={{paddingTop: '20px'}}>
                                        <PersonOutlineIcon style={{ fontSize: '32px', color: 'black' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0400, userUseCases) &&
                    <div className={classes.item}>
                        <Link to={isComponentEnabledForUser(UC0400, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/distributioncenterlist` : "#"}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <Typography variant="h4" style={{color: 'black', fontWeight: 600}}>{t('DISTRIBUTION_ADMINISTRATION')}</Typography>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large" style={{paddingTop: '20px'}}>
                                        <OtherHousesIcon style={{ fontSize: '32px', color: 'black' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0410, userUseCases) &&
                    <div className={classes.item}>
                        <Link to={isComponentEnabledForUser(UC0410, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/checkpointlist` : "#"}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <Typography variant="h4" style={{color: 'black', fontWeight: 600}}>{t('CHECKPOINT_ADMINISTRATION')}</Typography>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large" style={{paddingTop: '20px'}}>
                                        <PlaceIcon style={{ fontSize: '32px', color: 'black' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

            </div>
        </div>
    );
}

AdministrationList.propTypes = {};


export default AdministrationList;
