import { CAMERA_TYPE_BACK_LP, CAMERA_TYPE_BACK_STREAM, CAMERA_TYPE_FRONT_LP, CAMERA_TYPE_FRONT_STREAM, CAMERA_TYPE_MID_LP, CAMERA_TYPE_MID_STREAM, CAMERA_TYPE_SIDE_STREAM_LEFT, CAMERA_TYPE_SIDE_STREAM_RIGHT, STEP_FIVE, STEP_FOUR, STEP_SIX, STEP_THREE, STEP_TWO } from "./constants";

// Capitalization of string
export const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

// Get chip background color
export const getChipColor = (color) => {
    switch (color.toLowerCase()) {
        case 'red':
            return '#FBC1BA';
        case 'yellow':
            return '#FFDA98';
        case 'blue':
            return '#B6D3F9';
        default:
            return color;
    }
};

// Get chip font color
export const getChipFontColor = (color) => {
    switch (color.toLowerCase()) {
        case 'red':
            return '#F24735';
        case 'yellow':
            return '#E99708';
        case 'blue':
            return '#5985EE';
        default:
            return color.indexOf('#') > -1 ? invertColor(color) : color;
    }
};

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export const isComponentVisibleForUser = (componentUseCase, userUseCases) => {
    if ( userUseCases ) {
        let tmp = userUseCases[componentUseCase];
        return tmp;
    } else
        return true;
};

export const isComponentEnabledForUser = (componentUseCase, userUseCases) => {
    if ( userUseCases )
        return userUseCases[componentUseCase] && userUseCases[componentUseCase].execute;
    else
        return true;
};

export const getCodeList = () => {

};

export const convertDateToISOString = date => {
    date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    date = date.toISOString().slice(0, 10);
    return date;
};

// Get task state description
export const getErrorMessage = (error, t) => {
    let errorCode = error && error.data && error.data.errorCode ? error.data.errorCode : 'E500';
    errorCode = error && error.response && error.response.data && error.response.data.errorCode ? error.response.data.errorCode : errorCode;
    let params = error && error.data ? error.data.errorParams : [];
    params = error && error.response && error.response.data ? error.response.data.errorParams : params;
    const tparams = {};
    if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            tparams[`param${i + 1}`] = params[i];
        }
    }
    return t(errorCode, tparams);
};

export const getCameraCode = (step) => {
    if ( step===STEP_TWO ) {
        return [CAMERA_TYPE_FRONT_LP, CAMERA_TYPE_FRONT_STREAM];
    } else if ( step===STEP_THREE ) {
        return [CAMERA_TYPE_MID_LP, CAMERA_TYPE_MID_STREAM];
    } else if ( step===STEP_FOUR ) {
        return [CAMERA_TYPE_BACK_LP, CAMERA_TYPE_BACK_STREAM];
    } else if ( step===STEP_FIVE ) {
        return ['', CAMERA_TYPE_SIDE_STREAM_LEFT];
    } else if ( step===STEP_SIX ) {
        return ['', CAMERA_TYPE_SIDE_STREAM_RIGHT];
    }
};