import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ActionButton } from "../common";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        flexDirection: props => props.flexDirection,
        justifyContent: 'space-evenly',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#aaaaaa',
    },
    title: {
        background: props => props.headerColor,
        padding: theme.spacing(4,6,4,6),
        fontSize: 22,
        fontWeight: 600,
        color: "white",
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "& .MuiButtonBase-root": {
            borderRadius: 30,
        },
    },
    dialogPaper: {
        height: props => `${props.height}px`,
        borderRadius: 15,
    },
    divider: {
        color: '#aaaaaa',
        margin: '1px'
    }
}));

const PDSDialog = ({
    open,
    title,
    onClose,
    onSubmit,
    size,
    height,
    headerColor,
    children,
    actionType,
    flexDirection,
    showCloseButton,
    showCheckIcon,
}) => {
    const classes = useStyles({ headerColor, height, flexDirection });
    const { t } = useTranslation();

    return (
        <Dialog
            disableEnforceFocus
            maxWidth={size}
            fullWidth={true}
            open={open}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper }}
            onKeyUp={(e) => {
                if (open) {
                    const ENTER = 13;
                    console.warn("submittting");
                    if (e.keyCode === ENTER && !e.shiftKey) {
                        onSubmit && onSubmit();
                    }
                }
            }}
        >
            <DialogTitle className={classes.title}>
                {title ? title : ''}
                {
                    showCloseButton && <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                }
            </DialogTitle>

            <Divider className={classes.divider} />

            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <ActionButton
                    action="closeModal"
                    handleClick={onClose}
                />
                {onSubmit && <ActionButton
                    muiIcon={showCheckIcon ? <Check /> : null}
                    type="submit"
                    action={actionType}
                    handleClick={onSubmit}
                />}
            </DialogActions>
        </Dialog>
    );
}

PDSDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    showCloseButton: PropTypes.bool,
    showCheckIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    headerColor: PropTypes.string,
    actionType: PropTypes.string,
    flexDirection: PropTypes.string,
};


PDSDialog.defaultProps = {
    children: null,
    height: 550,
    size: 'sm',
    headerColor: '#F5894A',
    actionType: 'saveModal',
    flexDirection: 'column',
    showCloseButton: true,
    showCheckIcon: true,
};

export default PDSDialog;


