import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {ActionButton} from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import UserUpdate from "./UserUpdate";
import {CircularProgress, Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            borderRadius: '20px',
        },
        height: '100%',
        overflowX: 'hidden',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    titleText: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    button2: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));


function User(props) {
    const {
        userUseCases,
        roles,
        rowClickHandel,
        headerClickHandel,
        headerChecked,
        handleClose,
        saveUser,
        userConfigModel,
        tenants,
        snackbarOpen,
        setSnackbarOpen,
        error,
        setError,
        saving,
        setSaving,
        loggedUser
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const [updatedUser, setUpdatedUser] = useState();
    const [errorStep1, setErrorStep1] = useState('');

    useEffect(() => {
        if (userConfigModel.user)
            setUpdatedUser(userConfigModel.user);
    }, [userConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedUser.lastName || updatedUser.lastName.trim().length === 0) {
            error.lastName = 'required';
            ret = false;
        }
        if (!updatedUser.userName || updatedUser.userName.trim().length === 0) {
            error.userName = 'required';
            ret = false;
        }
        if (!updatedUser.email || updatedUser.email.trim().length === 0) {
            error.email = 'required';
            ret = false;
        }
        if (!updatedUser.password && isNew) {
            error.password = 'required';
            ret = false;
        }
        if (!updatedUser.active) {
            error.active = 'required';
            ret = false;
        }

        setErrorStep1(error);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedUser({...updatedUser, [name]: event.target.value});
        } else {
            setUpdatedUser({...updatedUser, [name]: event});
        }
    }

    return (
        <Dialog
            className={classes.container}
            open={userConfigModel.open}
            onClose={handleClose}
        >
            <DialogTitle>
                <div className={classes.title}>
                    <Grid container >
                        <Grid item xs={2} className={classes.titleText}>
                            {userConfigModel && userConfigModel.readOnly > 0 ? t('DETAIL_USER') : (userConfigModel.isNew ? t('CREATE_USER') : t('UPDATE_USER')) }
                        </Grid>
                        <Grid item xs={10}/>
                    </Grid>
                </div>

            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error}/>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <UserUpdate 
                        userUseCases={userUseCases}
                        roles={roles}
                        rowClickHandel={rowClickHandel}
                        headerClickHandel={headerClickHandel}
                        headerChecked={headerChecked}
                        handleChange={handleChange}
                        updatedUser={updatedUser}
                        error={errorStep1}
                        readOnly={userConfigModel.readOnly}
                        setSnackbarError={setError}
                        setSnackbarOpen={setSnackbarOpen}
                        isNew={userConfigModel.isNew}
                        tenants={tenants}
                        loggedUser={loggedUser}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                        {!userConfigModel.readOnly &&
                            <>
                                <ActionButton
                                    
                                    loading={saving}
                                    action="save" handleClick={() => {
                                        const ret = isValid(userConfigModel.isNew);
                                        if (ret) {
                                            saveUser(updatedUser);
                                            setSaving(true);
                                        }
                                    }}
                                    className={classes.button2}
                                />
                                {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </>
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

User.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(User);


