import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import { Grid } from "@mui/material";
import SupervisionSetting from './SupervisionSetting';


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 955,
            minHeight: '90%',
            maxWidth: '100%',
            borderRadius: '20px',
            overflowX: 'hidden',
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    titleText: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    titleText2: {
        color: theme.palette.base[500],
        cursor: 'pointer',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    titleTextDisabled: {
        color: theme.palette.base[300],
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));


const CheckpointSupervisionUpdate = (props) => {
    const {
        userUseCases,
        useCases,
        handleClose,
        checkpointConfigModel,
        error,
        snackbarOpen,
        setSnackbarOpen,
    } = props;


    const classes = useStyles();

    const { t } = useTranslation();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const BookmarkButton = ({ stepName }) => (
        <Grid item xs={1.7} className={classes.titleText}>
            <div>{t(stepName)}</div>
        </Grid>
    );

    return (
        <Dialog
            className={classes.container}
            open={checkpointConfigModel.supervisionOpen}
            onClose={handleClose}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle>
                <div className={classes.title}>
                    <Grid container >
                        <BookmarkButton stepName={'SUPERVISION'} />
                    </Grid>
                </div>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error} />

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <SupervisionSetting
                        userUseCases={userUseCases}
                        useCases={useCases}
                        updatedCheckpoint={checkpointConfigModel.checkpoint}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            action="done"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

CheckpointSupervisionUpdate.propTypes = {};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CheckpointSupervisionUpdate);


