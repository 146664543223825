import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import { isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Add from "@mui/icons-material/Add";
import Autocomplete from "@mui/lab/Autocomplete";
import { Chip } from "@mui/material";
import { UC0102 } from '../../../utils/constants';
import TableGridIcon from '../../../components/common/TableGridIcon';
import DefaultToolBar from '../../../components/common/DefaultTopToolbar';

const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(1),
        marginTop: '-5px',
        // padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleUserUpdate,
        selectedName,
        handleSelectedName,
        selectedUCName,
        handleSelectedUCName,
        selectedStatus,
        handleSelectedStatus,
        selectedAdminRole,
        handleSelectedAdminRole,
        selectedRole,
        handleSelectedRole,
        roles,
        user,
        table,
        setTable
    } = props;

    const { t } = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    const admin = [
        {
            value: 'YES',
            label: t('YES'),
            key: 'YES'
        },
        {
            value: 'NO',
            label: t('NO'),
            key: 'NO'
        },
    ]

    return (
        <DefaultToolBar addButton={
            isComponentVisibleForUser(UC0102, userUseCases) &&
            <IconButton
                className={classes.addButton}
                edge="start"
                color="inherit"
                aria-label="add role"
                onClick={() => {
                    handleUserUpdate({ userId: 0, tenantId: user.tenantId }, true);
                }}
                disabled={!isComponentEnabledForUser(UC0102, userUseCases)}
                size="large">
                <Add className={classes.addIcon} />
            </IconButton>
        }>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="search"
                    label={t('NAME')}
                    fullWidth
                    value={selectedName || ''}
                    onChange={handleSelectedName}
                    InputProps={{ sx: { borderRadius: 30 } }}
                />

            </Grid>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="search"
                    label={t('UC_NAME')}
                    fullWidth
                    value={selectedUCName || ''}
                    onChange={handleSelectedUCName}
                    InputProps={{ sx: { borderRadius: 30 } }}
                />
            </Grid>

            <Grid item sm={2}>

                <Autocomplete
                    size="small"
                    value={selectedStatus}
                    onChange={(event, newValue) => {
                        handleSelectedStatus(newValue);
                    }}
                    id="statuses"
                    options={statuses}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('STATE')} fullWidth
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingLeft: "20px !important",
                                    borderRadius: "50px"
                                },
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={2}>
                <Autocomplete
                    size="small"
                    value={selectedRole}
                    onChange={(event, newValue) => {
                        handleSelectedRole(newValue);
                    }}
                    id="role"
                    options={roles || []}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('ROLE')} fullWidth
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingLeft: "20px !important",
                                    borderRadius: "50px"
                                },
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={2}>
                <Autocomplete
                    size="small"
                    value={selectedAdminRole}
                    onChange={(event, newValue) => {
                        handleSelectedAdminRole(newValue);
                    }}
                    id="adminRole"
                    options={admin}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('ADMIN_ROLE')} fullWidth
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingLeft: "20px !important",
                                    borderRadius: "50px"
                                },
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={1} />

            <Grid item sm={1} >
                <TableGridIcon table={table} setTable={setTable} />
            </Grid>

        </DefaultToolBar>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
