import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import { makeStyles } from '@mui/styles';
import {CustomTable, Loading} from '../../../components/elements';
import {connect} from 'react-redux';
import {ActionButton} from "../../../components/common";
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import PlusIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';
import PDSDialog from '../../../components/common/PDSDialog';
import { UC0115, UC0113, UC0114 } from '../../../utils/constants';
import { Grid, useTheme } from '@mui/material';
import ActivityState from '../../../components/common/ActivityState';
import AdminState from '../../../components/common/AdminState';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 24,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    roleCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.green,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    roleCardDeactivate: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.disabledGray,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%',
        textAlign: 'center',
    },
    root: {
        height: 54,
        backgroundColor: theme.palette.background.gray,
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        paddingTop: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    state: {
        display: 'flex',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 16,
        paddingBottom: 4,
        paddingTop: 0,
        marginTop: -6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 20,
        fontSize: 22,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 12,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 25,
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.background.green,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.background.red,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 14,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 14,
        textAlign: 'center',
    },
    value2: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function RoleList(props) {
    const classes = useStyles();
    const { palette } = useTheme();
    const {t} = useTranslation();
    const {
        role,
        userUseCases,
        isLoading,
        handleRoleDetail,
        handleRoleUpdate,
        handleActivate,
        save,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
        confirmModalOpened,
        setConfirmModalOpened,
        table,
    } = props;
    
    const [roleToActive, setRoleToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const saveInner = (role) => {
        setActionInProgress(true);
        save(role).then(
            () => {
                refresh();
                setActionInProgress(false);
            }
        ).catch((error) => {
            setDeletionSnackbarError(getErrorMessage(error, t));
            setActionInProgress(false);
        });
    }

    const renderRoleAdminTable = (param) => {
        if ( param && param.admin) {
            return <>{t('YES')}</>
        } else {
            return <>{t('NO')}</>
        }
    }

    const columns = [
        {   title: t('STATE'),
            field: 'active',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                <ActivityState active={rowData?.active} />
            )
        },
        {
            title: t('ROLE_NAME'),
            field: 'name',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('UC_COUNT'),
            field: 'ucCount',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.ucCount
            )
        },
        {
            title: t('USER_COUNT'),
            field: 'userCount',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.userCount
            ),
        },
        {
            title: t('ROLE_ADMIN'),
            field: 'admin',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleAdminTable(rowData)
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0113, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {handleRoleDetail(rowData)}}
                            disabled={!isComponentEnabledForUser(UC0113, userUseCases) || actionInProgress}
                            className={classes.green2}
                            action="detail"
                        />
                    }
                    {isComponentVisibleForUser(UC0115, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {handleRoleUpdate(rowData, false)}}
                            disabled={!isComponentEnabledForUser(UC0115, userUseCases) || actionInProgress}
                            className={classes.red2}
                            action="edit"
                        />
                    }
                    {isComponentVisibleForUser(UC0114, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {
                                setRoleToActive(rowData);
                                setConfirmModalOpened(true);
                            }}
                            disabled={!isComponentEnabledForUser(UC0114, userUseCases) || actionInProgress}
                            className={classes.blue2}
                            action={rowData.active?t('DEACTIVATE'):t('ACTIVATE')}
                        />
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
            && (    
                <PDSDialog
                    open={confirmModalOpened}
                    title={`${roleToActive.active?t('ROLE_INACTIVATION'):t('ROLE_ACTIVATION')}`}
                    onClose={() => {
                        setConfirmModalOpened(false);
                        setRoleToActive(null);
                    }}
                    size="md"
                    headerColor={roleToActive.active ? palette.primary.red : palette.primary.blue}
                    height={450}
                    onSubmit={ () => {handleActivate(roleToActive);}}
                    actionType={roleToActive.active?'roleDeactivate':'roleActivate'}
                    showCloseButton={false}
                    showCheckIcon={false}
                >
                    
                    <div className={classes.content}>
                    {roleToActive.active?t('CONFIRM_ROLE_DEACTIVE', {roleName: roleToActive.name}):t('CONFIRM_ROLE_ACTIVE', {roleName: roleToActive.name})}
                    </div>
                </PDSDialog>
            )}


            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={role}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                role.map((roleItem, index) => (
                    <div className={roleItem.active ? classes.roleCard : classes.roleCardDeactivate} key={roleItem.roleId}>
                        <Grid container className={classes.row1} >
                            <Grid item xs={1.5}>
                                <ActivityState active={roleItem?.active} />
                            </Grid>
                            <Grid item xs={1.5}>
                                <AdminState param={roleItem} />
                            </Grid>
                            <Grid item xs={6}>
                                <Tooltip title={t('NAME')}>
                                    <Typography variant="h2" style={{ fontWeight: 600 }}>{roleItem.name}</Typography>
                                </Tooltip>
                            </Grid>
                            <Grid 
                                item xs={3}
                                container
                                direction="column"
                                alignItems="flex-end"
                                justify="flex-start"                            
                            >
                                {isComponentVisibleForUser(UC0115, userUseCases) &&
                                    <Tooltip title={t('EDIT')}>
                                        <EditIcon 
                                            fontSize='small'
                                            onClick={() => {
                                                handleRoleUpdate(roleItem, false)
                                            }}
                                            disabled={!isComponentEnabledForUser(UC0115, userUseCases)}
                                            style={{ fontSize: 30, marginTop: -6 }}
                                        />
                                    </Tooltip>
                                }                                
                            </Grid>
                        </Grid>
                        <div className={classes.row2}>

                            <div className={classes.rowColumn}>
                                <div className={classes.label2}>{t('USER_COUNT')}:</div>
                                <div className={classes.value2}>{roleItem.userCount}</div>
                            </div>
                            <div className={classes.rowColumn2}>
                            </div>
                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('UC_COUNT')}:</div>
                                <div className={classes.value}>{roleItem.ucCount}</div>
                            </div>
                        </div>
                        <Grid container className={classes.root} >
                            <Grid item xs={6}>
                                {isComponentVisibleForUser(UC0113, userUseCases) &&
                                    <Tooltip title={t('SHOW_ROLE_DETAIL')}>
                                        <InfoIcon 
                                            fontSize='large' 
                                            disabled={actionInProgress || !isComponentEnabledForUser(UC0113, userUseCases)}
                                            onClick={() => {handleRoleDetail(roleItem)}}
                                            style={{ fontSize: 30 }}
                                        />
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {isComponentVisibleForUser(UC0114, userUseCases) &&
                                    <Tooltip title={roleItem.active?t('SHOW_ROLE_DEACTIVE'):t('SHOW_ROLE_ACTIVE')}>
                                        { roleItem.active === true ? 
                                            <CancelIcon 
                                                fontSize='large' 
                                                disabled={actionInProgress || !isComponentEnabledForUser(UC0114, userUseCases)}
                                                onClick={() => {
                                                    setRoleToActive(roleItem);
                                                    setConfirmModalOpened(true);
                                                }}
                                                style={{ fontSize: 30 }}
                                            /> : 
                                            <PlusIcon 
                                                fontSize='large' 
                                                disabled={actionInProgress || !isComponentEnabledForUser(UC0114, userUseCases)} 
                                                onClick={() => {
                                                    setRoleToActive(roleItem);
                                                    setConfirmModalOpened(true);
                                                }}
                                                style={{ fontSize: 30 }}
                                            /> 
                                        }
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </div>
                ))
                :
                <Loading/>
            }
        </div>
    );
}

RoleList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleList);