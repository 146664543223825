export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // code list
    CODELIST_FETCH_REQUEST: 'CODELIST_FETCH_REQUEST',
    CODELIST_FETCH_SUCCESS: 'CODELIST_FETCH_SUCCESS',
    CODELIST_FETCH_FAIL: 'CODELIST_FETCH_FAIL',
    
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // users
    USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    USERS_FETCH_FAIL: 'USERS_FETCH_FAIL',

    USERS_BY_ROLE_FETCH_REQUEST: 'USERS_BY_ROLE_FETCH_REQUEST',
    USERS_BY_ROLE_FETCH_SUCCESS: 'USERS_BY_ROLE_FETCH_SUCCESS',
    USERS_BY_ROLE_FETCH_FAIL: 'USERS_BY_ROLE_FETCH_FAIL',

    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CREATE_FAIL: 'USER_CREATE_FAIL',
    
    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',
    
    USER_PUSH_REGISTRATION_REQUEST: 'USER_PUSH_REGISTRATION_REQUEST',
    USER_PUSH_REGISTRATION_SUCCESS: 'USER_PUSH_REGISTRATION_SUCCESS',
    USER_PUSH_REGISTRATION_FAIL: 'USER_PUSH_REGISTRATION_FAIL',

    // roles
    ROLES_FETCH_REQUEST: 'ROLES_FETCH_REQUEST',
    ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
    ROLES_FETCH_FAIL: 'ROLES_FETCH_FAIL',

    ROLES_UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    ROLES_UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    ROLES_UPDATE_FAIL: 'ROLES_UPDATE_FAIL',

    ROLES_ACTIVE_REQUEST: 'ROLES_ACTIVE_REQUEST',
    ROLES_ACTIVE_SUCCESS: 'ROLES_ACTIVE_SUCCESS',
    ROLES_ACTIVE_FAIL: 'ROLES_ACTIVE_FAIL',

    ROLES_CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
    ROLES_CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',
    ROLES_CREATE_FAIL: 'ROLES_CREATE_FAIL',

    ROLES_ADD_ROLE_REQUEST: 'ROLES_ADD_ROLE_REQUEST',
    ROLES_ADD_ROLE_SUCCESS: 'ROLES_ADD_ROLE_SUCCESS',
    ROLES_ADD_ROLE_FAIL: 'ROLES_ADD_ROLE_FAIL',

    ROLES_REMOVE_ROLE_REQUEST: 'ROLES_REMOVE_ROLE_REQUEST',
    ROLES_REMOVE_ROLE_SUCCESS: 'ROLES_REMOVE_ROLE_SUCCESS',
    ROLES_REMOVE_ROLE_FAIL: 'ROLES_REMOVE_ROLE_FAIL',

    // distribution centers
    CENTER_FETCH_REQUEST: 'CENTER_FETCH_REQUEST',
    CENTER_FETCH_SUCCESS: 'CENTER_FETCH_SUCCESS',
    CENTER_FETCH_FAIL: 'CENTER_FETCH_FAIL',

    CENTER_CREATE_REQUEST: 'CENTER_CREATE_REQUEST',
    CENTER_CREATE_SUCCESS: 'CENTER_CREATE_SUCCESS',
    CENTER_CREATE_FAIL: 'CENTER_CREATE_FAIL',

    CENTER_UPDATE_REQUEST: 'CENTER_UPDATE_REQUEST',
    CENTER_UPDATE_SUCCESS: 'CENTER_UPDATE_SUCCESS',
    CENTER_UPDATE_FAIL: 'CENTER_UPDATE_FAIL',

    CENTER_STATE_REQUEST: 'CENTER_STATE_REQUEST',
    CENTER_STATE_SUCCESS: 'CENTER_STATE_SUCCESS',
    CENTER_STATE_FAIL: 'CENTER_STATE_FAIL',

    // checkpoint
    CHECKPOINT_FETCH_REQUEST: 'CHECKPOINT_FETCH_REQUEST',
    CHECKPOINT_FETCH_SUCCESS: 'CHECKPOINT_FETCH_SUCCESS',
    CHECKPOINT_FETCH_FAIL: 'CHECKPOINT_FETCH_FAIL',

    CHECKPOINT_SUPERVISION_REQUEST: 'CHECKPOINT_SUPERVISION_REQUEST',
    CHECKPOINT_SUPERVISION_SUCCESS: 'CHECKPOINT_SUPERVISION_SUCCESS',
    CHECKPOINT_SUPERVISION_FAIL: 'CHECKPOINT_SUPERVISION_FAIL',

    CHECKPOINT_SUPERVISORS_REQUEST: 'CHECKPOINT_SUPERVISORS_REQUEST',
    CHECKPOINT_SUPERVISORS_SUCCESS: 'CHECKPOINT_SUPERVISORS_SUCCESS',
    CHECKPOINT_SUPERVISORS_FAIL: 'CHECKPOINT_SUPERVISORS_FAIL',

    CHECKPOINT_CREATE_REQUEST: 'CHECKPOINT_CREATE_REQUEST',
    CHECKPOINT_CREATE_SUCCESS: 'CHECKPOINT_CREATE_SUCCESS',
    CHECKPOINT_CREATE_FAIL: 'CHECKPOINT_CREATE_FAIL',

    CHECKPOINT_UPDATE_REQUEST: 'CHECKPOINT_UPDATE_REQUEST',
    CHECKPOINT_UPDATE_SUCCESS: 'CHECKPOINT_UPDATE_SUCCESS',
    CHECKPOINT_UPDATE_FAIL: 'CHECKPOINT_UPDATE_FAIL',

    CHECKPOINT_STATE_REQUEST: 'CHECKPOINT_STATE_REQUEST',
    CHECKPOINT_STATE_SUCCESS: 'CHECKPOINT_STATE_SUCCESS',
    CHECKPOINT_STATE_FAIL: 'CHECKPOINT_STATE_FAIL',


    // camera configuration
    CAMERACONFIGURATION_CREATE_REQUEST: 'CAMERACONFIGURATION_CREATE_REQUEST',
    CAMERACONFIGURATION_CREATE_SUCCESS: 'CAMERACONFIGURATION_CREATE_SUCCESS',
    CAMERACONFIGURATION_CREATE_FAIL: 'CAMERACONFIGURATION_CREATE_FAIL',

    CAMERACONFIGURATION_UPDATE_REQUEST: 'CAMERACONFIGURATION_UPDATE_REQUEST',
    CAMERACONFIGURATION_UPDATE_SUCCESS: 'CAMERACONFIGURATION_UPDATE_SUCCESS',
    CAMERACONFIGURATION_UPDATE_FAIL: 'CAMERACONFIGURATION_UPDATE_FAIL',

    CAMERACONFIGURATION_TEST_REQUEST: 'CAMERACONFIGURATION_TEST_REQUEST',
    CAMERACONFIGURATION_TEST_SUCCESS: 'CAMERACONFIGURATION_TEST_SUCCESS',
    CAMERACONFIGURATION_TEST_FAIL: 'CAMERACONFIGURATION_TEST_FAIL',

    // security check
    SECURITY_CHECK_SSE_FETCH_SUCCESS: 'SECURITY_CHECK_SSE_FETCH_SUCCESS',

    SECURITY_CHECK_FINISH_REQUEST: 'SECURITY_CHECK_FINISH_REQUEST',
    SECURITY_CHECK_FINISH_SUCCESS: 'SECURITY_CHECK_FINISH_SUCCESS',
    SECURITY_CHECK_FINISH_FAIL: 'SECURITY_CHECK_FINISH_FAIL',
    
};
