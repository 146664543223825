import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {ActionButton} from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import RoleUpdate from "./RoleUpdate";
import {CircularProgress, Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            borderRadius: '20px',
        },
        height: '100%',
        overflowX: 'hidden',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    titleText: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    button2: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));


function Role(props) {
    const {
        userUseCases,
        useCases,
        rowClickHandel,
        rowSetExpanded,
        headerClickHandel,
        headerChecked,
        handleClose,
        readOnly,
        saveRole,
        setIsFetchedRoles,
        roleConfigModel,
        error,
        setError,
        saving,
        setSaving,
        snackbarOpen,
        setSnackbarOpen,
        user,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [updatedRole, setUpdatedRole] = useState();
    const [errorStep1, setErrorStep1] = useState({});

    useEffect(() => {
        if (roleConfigModel.role) {
            setUpdatedRole(roleConfigModel.role);
        }
        setErrorStep1({});
    }, [roleConfigModel]);

    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedRole.name || updatedRole.name.trim().length === 0) {
            error.name = 'required';
            ret = false;
        }
        if (!updatedRole.active) {
            error.active = 'required';
            ret = false;
        }
        if (user.admin && !updatedRole.admin) {
            error.admin = 'required';
            ret = false;
        }
        // console.log('error', error, updatedRole);
        setErrorStep1(error);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedRole({...updatedRole, [name]: event.target.value});
        } else {
            setUpdatedRole({...updatedRole, [name]: event});
        }
    }

    return (
        <Dialog
            className={classes.container}
            open={roleConfigModel.open}
            onClose={handleClose}
        >
            <DialogTitle> 
                <div className={classes.title}>
                    <Grid container >
                        <Grid item xs={2} className={classes.titleText}>
                            {updatedRole && updatedRole.roleId > 0 ? t('UPDATE_ROLE') : t('CREATION_ROLE') }
                        </Grid>
                        <Grid item xs={10}/>
                    </Grid>
                </div>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error}/>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <RoleUpdate 
                        userUseCases={userUseCases}
                        useCases={useCases}
                        rowClickHandel={rowClickHandel}
                        rowSetExpanded={rowSetExpanded}
                        headerClickHandel={headerClickHandel}
                        headerChecked={headerChecked}
                        handleChange={handleChange}
                        updatedRole={updatedRole}
                        error={errorStep1}
                        readOnly={readOnly}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />

                        <ActionButton
                            
                            loading={saving}
                            action="save" handleClick={() => {
                                const ret = isValid();
                                if (ret) {
                                    saveRole(updatedRole);
                                    setSaving(true);
                                }
                            }}
                            className={classes.button2}
                        />
                        {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

Role.propTypes = {};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Role);


