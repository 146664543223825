import httpService from './http.service';


const createCheckpoint = (center) => httpService
    .post(`/checkpoints`, center)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateCheckpoint = (center) => httpService
    .put(`/checkpoints`, center)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateState = (checkpointId, active) => httpService
    .put(`/checkpoints/${checkpointId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getAllCheckpoints = () => httpService
    .get('/checkpoints')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const addSupervision = (userId, checkpointId) => httpService
    .put(`/checkpoints/${checkpointId}/add/${userId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const removeSupervision = (userId, checkpointId) => httpService
    .put(`/checkpoints/${checkpointId}/remove/${userId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getCheckpointSupervisors = (checkpointId) => httpService
    .get(`/checkpoints/${checkpointId}/supervisors`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getAllCheckpoints, 
    createCheckpoint, 
    updateCheckpoint, 
    updateState, 
    addSupervision, 
    removeSupervision,
    getCheckpointSupervisors,
};
