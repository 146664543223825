import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAllCheckpoints : false,
  isStateUpdating: false,
  isCreating: false,
  isUpdating: false,
  isSupervisionUpdating: false,
  isFetchingSupervisors: false,
  checkpoints: [],
  supervisors: {},
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CHECKPOINT_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAllCheckpoints: true,
      };
    case types.CHECKPOINT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllCheckpoints: false,
        checkpoints: action.payload.checkpoints,
      };
    case types.CHECKPOINT_FETCH_FAIL:
      return {
        ...state,
        isFetchingAllCheckpoints: false,
        errors: action.payload.error,
      };

    case types.CHECKPOINT_STATE_REQUEST:
      return {
        ...state,
        isStateUpdating: true,
      }
    case types.CHECKPOINT_STATE_SUCCESS:
      return {
        ...state,
        isStateUpdating: false,
      };
    case types.CHECKPOINT_STATE_FAIL:
      return {
        ...state,
        isStateUpdating: false,
        errors: action.payload.error,
      };

    case types.CHECKPOINT_CREATE_REQUEST:
      return {
          ...state, 
          isCreating: true
      };
    case types.CHECKPOINT_CREATE_SUCCESS:
      return {
          ...state,
          checkpoint: action.payload.checkpoint,
          isCreating: false
      };
    case types.CHECKPOINT_CREATE_FAIL:
      return {
          ...state, 
          isCreating: false,
          errors: action.payload.error,
      };

    case types.CHECKPOINT_UPDATE_REQUEST:
      return {
          ...state, 
          isUpdating: true
      };
    case types.CHECKPOINT_UPDATE_SUCCESS:
      return {
          ...state,
          checkpoint: action.payload.checkpoint,
          isUpdating: false
      };
    case types.CHECKPOINT_UPDATE_FAIL:
      return {
          ...state, 
          isUpdating: false,
          errors: action.payload.error,
      };
    
    case types.CHECKPOINT_SUPERVISION_REQUEST:
      return {
          ...state,
          isSupervisionUpdating: true
      };
    case types.CHECKPOINT_SUPERVISION_SUCCESS:
      return {
          ...state,
          isSupervisionUpdating: false
      };
    case types.CHECKPOINT_SUPERVISION_FAIL:
      return {
          ...state,
          isSupervisionUpdating: false,
          errors: action.payload.error,
      };

    case types.CHECKPOINT_SUPERVISORS_REQUEST:
      return {
          ...state,
          isFetchingSupervisors: true
      };
    case types.CHECKPOINT_SUPERVISORS_SUCCESS:
      return {
          ...state,
          isFetchingSupervisors: false,
          supervisors: action.payload
      };
    case types.CHECKPOINT_SUPERVISORS_FAIL:
      return {
          ...state,
          isFetchingSupervisors: false,
          errors: action.payload.error,
      };
    default:
      return state;
  }
};
