import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '0px',
    },
    editorWrapper: {
        height: '550px',
        minWidth: '1250px',
        width: '100%',
        padding: theme.spacing(2, 2, 2, 2),
        // position: 'fixed'
    },
    description: {
        color: 'rgba(0, 0, 0, 0.9)',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '7px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    roleBox1: {
        padding: theme.spacing(2),
        marginRight: '15px',
        border: '1px solid #000000',
        borderRadius: '15px',
    },
    roleBox2: {
        marginRight: "auto", 
        border: '1px solid #000000', 
        borderRadius: '15px',
        height: '551px',
    },
}));

function CenterUpdate(props) {
    const classes = useStyles();

    const {
        handleChange,
        updatedCenter,
        setUpdatedCenter,
        error,
        readOnly,
    } = props;

    const { t } = useTranslation();

    const LocationMarker = () => {
        const map = useMapEvents({
            loading(){
                console.log('map loaded');
                map.locate()
            },
            load(){
                console.log('map loaded');
                map.locate()
            },
            click(e) {
                if (!readOnly) {
                    setUpdatedCenter({...updatedCenter, lat: e.latlng.lat, lng : e.latlng.lng});
                }
            },
            locationfound(e) {
                setUpdatedCenter({...updatedCenter, lat: e.latlng.lat, lng : e.latlng.lng});
                map.flyTo(e.latlng, map.getZoom())
            },
        })
      
        return (
          <Marker position={{lat: updatedCenter && updatedCenter.lat ? updatedCenter.lat : '50.05193', lng: updatedCenter && updatedCenter.lng ? updatedCenter.lng : '14.42686'}}>
            <Popup>You distribution center is here.</Popup>
          </Marker>
        );
    }

    return (
        <div className={classes.formFields}>
            <Grid container rowSpacing={2} columnSpacing={5}>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}></Grid>

                <Grid item sm={3}>
                    <Typography variant="h6" className={classes.description}>
                        {t('DISTRIBUTION_CENTER_BASIC')}
                    </Typography>                            
                </Grid>
                <Grid item sm={9}>
                    <Typography variant="h6" className={classes.description}>
                        {t('DISTRIBUTION_CENTER_PLACE')}
                    </Typography>
                </Grid>

                <Grid item sm={3}></Grid>
                <Grid item sm={9}></Grid>

                <Grid item sm={3}>
                    <Grid container className={classes.roleBox1}>
                        <Grid item xs sm={12} paddingLeft={'20px'} paddingRight={'15px'}>
                            <TextField
                                variant="standard"
                                style={{ height: '80px' }}
                                autoFocus
                                helperText={error && error.name
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="name"
                                value={updatedCenter.name || ''}
                                onChange={handleChange('name', false)}
                                label={t('NAME')} 
                            />

                            <FormControl variant="standard" fullWidth disabled={false} style={{ height: '80px' }} required>
                                <InputLabel id="parking-label">
                                    {t('DISTRIBUTION_CENTER_ACTIVE')}
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    autoWidth
                                    disabled={readOnly}
                                    value={updatedCenter.active || ''}
                                    id="active"
                                    onChange={handleChange('active', true)}>
                                    <MenuItem value=''>-</MenuItem>
                                    <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                    <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                </Select>
                                <FormHelperText>  {error && error.active && (
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                )}</FormHelperText>
                            </FormControl>

                            <TextField
                                variant="standard"
                                style={{ height: '80px' }}
                                autoFocus
                                fullWidth
                                disabled={readOnly}
                                id="lng"
                                value={updatedCenter.lng || ''}
                                onChange={handleChange('lng', false)}
                                label={t('LANGTITUDE')} 
                            />

                            <TextField
                                variant="standard"
                                style={{ height: '80px' }}
                                autoFocus
                                fullWidth
                                disabled={readOnly}
                                id="lat"
                                value={updatedCenter.lat || ''}
                                onChange={handleChange('lat', false)}
                                label={t('LONGTITUDE')} 
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={9}>
                    <Grid container className={classes.roleBox2} >
                        <div className={classes.editorWrapper}>
                            <MapContainer
                                center={[50.05193, 14.42686]}
                                zoom={14}
                                maxZoom={18}
                                style={{ height: "100%", width: "100%" }}
                                position={[50.05193, 14.42686]}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                />

                                <LocationMarker />
                            </MapContainer>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

CenterUpdate.propTypes = {};
CenterUpdate.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CenterUpdate);


