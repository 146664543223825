import securityCheckService from "../../services/securityCheck.service";
import types from "../actionTypes";

export const finishSecurityCheck = (securityCheckId) => (dispatch, getState) => {
    if (getState().securityCheckData.isFinishing) {
        return Promise.reject();
    }

    dispatch({
        type: types.SECURITY_CHECK_FINISH_REQUEST,
    });

    return securityCheckService.finishSecurityCheck(securityCheckId)
        .then(() => {
            dispatch({
                type: types.SECURITY_CHECK_FINISH_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SECURITY_CHECK_FINISH_FAIL,
                payload: {error},
            });

            throw error;
        });
}
