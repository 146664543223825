import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        height: '46px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: theme.palette.background.gray,
        borderRadius: '30px',
        paddingBottom: '8px'
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function DefaultToolBar({ children, addButton }) {
    const classes = useStyles();

    return (
        <div className={classes.toolbar}>
            <form autoComplete='off'>
                <Box mb={2} height="100%" width="100%">
                    <Grid container spacing={0} style={{ height: '100%', width: '100%' }}>
                        <Grid item sm={!addButton ? 12 : 11.5}>
                            <Grid container rowSpacing={0.6} columnSpacing={3} style={{ height: '100%', width: '100%' }} className={classes.toolbarInner1}>
                                {children}
                            </Grid>
                        </Grid>

                        {!!addButton && <Grid item sm={0.5}>
                            {addButton}
                        </Grid>}
                    </Grid>
                </Box>
            </form>
        </div>
    );
}

DefaultToolBar.propTypes = {};

DefaultToolBar.defaultProps = {};

export default DefaultToolBar;
