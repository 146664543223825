import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomTable, Loading } from '../../elements';
import Tooltip from '@mui/material/Tooltip';
import SecurityIcon from '@mui/icons-material/Security';
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import { isComponentVisibleForUser, isComponentEnabledForUser } from "../../../utils";
import { ActionButton } from "../../common";
import { UC0103 } from '../../../utils/constants';
import ActivityState from '../../common/ActivityState';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '30px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

function SupervisionTable({
    supervisors,
    handleRemoveUser,
}) {
    const classes = useStyles();

    const { t } = useTranslation();

    const columns = [
        {
            title: t('STATE'),
            field: 'active',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (<ActivityState active={rowData?.active} />)
        },
        {
            title: t('FIRST_NAME'),
            field: 'firstName',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.firstName
            )
        },
        {
            title: t('LAST_NAME'),
            field: 'lastName',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.lastName
            )
        },
        {
            title: t('LOGIN'),
            field: 'login',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.userName
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    <ActionButton
                        handleClick={() => { handleRemoveUser(rowData) }}
                        className={classes.red2}
                        action="delete"
                    />
                </div>
            ),
        },
    ];

    const renderRoleState = (param) => {
        if (!param) {
            return <Tooltip title={t('ROLE_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }} /></Tooltip>
        } else {
            if (param.active)
                return <Tooltip title={t('ROLE_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }} /></Tooltip>
            else
                return <Tooltip title={t('ROLE_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }} /></Tooltip>
        }
    }

    return (
        <div className={classes.formFields}>
            <CustomTable
                title=""
                columns={columns}
                data={supervisors}
                options={tableOptions}
                isLoading={false}
                loadAllData={false}
            />
        </div>
    )
}

SupervisionTable.propTypes = {};
SupervisionTable.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SupervisionTable);


