import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import { isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Add from "@mui/icons-material/Add";
import Autocomplete from "@mui/lab/Autocomplete";
import { Chip } from "@mui/material";
import { UC0112 } from '../../../utils/constants'
import TocIcon from '@mui/icons-material/Toc';
import TableGridIcon from '../../../components/common/TableGridIcon';
import DefaultToolBar from '../../../components/common/DefaultTopToolbar';



const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(1),
        marginTop: '-5px',
        // padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleRoleUpdate,
        selectedRoleName,
        selectedUCName,
        handleSelectedRoleName,
        handleSelectedUCName,
        selectedStatus,
        handleSelectStatus,
        selectedAdminRole,
        handleSelectAdminRole,
        setTable,
        table
    } = props;

    const { t } = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    const admin = [
        {
            value: 'YES',
            label: t('YES'),
            key: 'YES'
        },
        {
            value: 'NO',
            label: t('NO'),
            key: 'NO'
        },
    ]

    return (
        <DefaultToolBar addButton={
            isComponentVisibleForUser(UC0112, userUseCases) &&
            <IconButton
                className={classes.addButton}
                edge="start"
                color="inherit"
                aria-label="add role"
                onClick={() => {
                    handleRoleUpdate({ roleId: 0 }, true);
                }}
                disabled={!isComponentEnabledForUser(UC0112, userUseCases)}
                size="large">
                <Add className={classes.addIcon} />
            </IconButton>
        }>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="search"
                    label={t('ROLE_NAME')}
                    fullWidth
                    value={selectedRoleName || ''}
                    onChange={handleSelectedRoleName}
                    InputProps={{ sx: { borderRadius: 30 } }}
                />
            </Grid>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="search"
                    label={t('UC_NAME')}
                    fullWidth
                    value={selectedUCName || ''}
                    onChange={handleSelectedUCName}
                    InputProps={{ sx: { borderRadius: 30 } }}
                />
            </Grid>

            <Grid item sm={2}>
                <Autocomplete
                    size="small"
                    value={selectedStatus}
                    onChange={(event, newValue) => {
                        handleSelectStatus(newValue);
                    }}
                    id="statuses"
                    options={statuses}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('STATE')} fullWidth
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingLeft: "20px !important",
                                    borderRadius: "50px"
                                },
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={2}>
                <Autocomplete
                    size="small"
                    value={selectedAdminRole}
                    onChange={(event, newValue) => {
                        handleSelectAdminRole(newValue);
                    }}
                    id="adminRole"
                    options={admin}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('ADMIN_ROLE')} fullWidth
                            sx={{
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingLeft: "20px !important",
                                    borderRadius: "50px"
                                },
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item sm={3} />
            <Grid item sm={1} >
                <TableGridIcon table={table} setTable={setTable} />
            </Grid>
        </DefaultToolBar>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
