import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { CustomButton } from '../elements';
import { capitalize } from '../../utils';

/**
 * @return {null}
 */
function ActionButton(props) {
    const {
        className,
        action,
        disabled,
        handleClick,
        muiIcon,
    } = props;
    const { t } = useTranslation();

    const getProps = (action) => {
        switch (action) {
            case 'assign':
                return {
                    label: t('ASSIGN_TASK'),
                };
            case 'create':
                return {
                    width: 110,
                    label: t('CREATE_TASK'),
                };
            case 'edit':
                return {
                    width: 80,
                    color: 'grey',
                    label: t('EDIT'),
                };
            case 'wizard':
                return {
                    width: 60,
                    color: 'green',
                    label: t('WIZARD'),
                };
            case 'restart':
                return {
                    width: 60,
                    color: 'yellow',
                    label: t('RESTART'),
                };
            case 'validate':
                return {
                    width: 100,
                    color: 'green',
                    label: t('VALIDATE'),
                };
            case 'activate':
                return {
                    width: 130,
                    color: 'green',
                    label: t('ACTIVATE'),
                };
            case 'invalidate':
                return {
                    width: 100,
                    color: 'red',
                    label: t('INVALIDATE'),
                };
            case 'deactivate':
                return {
                    width: 130,
                    color: 'red',
                    label: t('DEACTIVATE'),
                };
            case 'block':
                return {
                    width: 100,
                    color: 'red',
                    label: t('BLOCK'),
                };
            case 'unblock':
                return {
                    width: 100,
                    color: 'green',
                    label: t('UNBLOCK'),
                };
            case 'remove':
                return {
                    width: 110,
                    label: t('REMOVE_TASK'),
                    color: 'red',
                };
            case 'details':
                return {
                    width: 110,
                    label: t('VIEW_MORE'),
                };
            case 'supervision':
                return {
                    width: 110,
                    label: t('SUPERVISION'),
                };
            case 'cancel':
                return {
                    width: 110,
                    label: t('CANCEL'),
                    color: 'red',
                };
            case 'renew':
                return {
                    width: 110,
                    label: t('RENEW'),
                    color: 'blue',
                };
            case 'units':
                return {
                    width: 110,
                    label: t('EDIT_UNITS'),
                    color: 'blue',
                };
            case 'close':
                return {
                    width: 110,
                    label: t('CLOSE'),
                    color: 'red',
                };
            case 'done':
                return {
                    width: 110,
                    label: t('DONE'),
                    color: 'green',
                };
            case 'remove_user':
                return {
                    width: 110,
                    label: t('REMOVE_USER'),
                    color: 'red',
                };
            case 'history':
                return {
                    width: 110,
                    label: t('HISTORY'),
                    color: 'blue',
                };
            case 'confirm_remove_user':
                return {
                    width: 110,
                    label: t('REMOVE_USER'),
                    color: 'blue',
                };
            case 'blacklist':
                return {
                    width: 110,
                    label: t('BLACKLIST'),
                    color: 'blue',
                };
            case 'confirm':
                return {
                    width: 100,
                    label: t('CONFIRM'),
                };
            case 'finish':
                return {
                    width: 100,
                    label: t('FINISH'),
                };
            case 'next':
                return {
                    width: 100,
                    label: t('NEXT'),
                    color: 'green',
                };
            case 'back':
                return {
                    width: 100,
                    label: t('BACK'),
                    color: 'green',
                };
            case 'backGrey':
                return {
                    width: 100,
                    label: t('BACK2'),
                    color: 'grey',
                };
            case 'delete':
                return {
                    width: 190,
                    color: 'red',
                    label: t('DELETE'),
                };
            case 'pay':
                return {
                    width: 100,
                    label: t('PAY'),
                };
            case 'save':
                return {
                    width: 100,
                    label: t('SAVE'),
                    color: 'green',
                };
            case 'saveAndClose':
                return {
                    width: 110,
                    label: t('SAVE_AND_CLOSE'),
                    color: '#50A932',
                };
            case 'eet':
                return {
                    width: 110,
                    label: t('EET'),
                    color: '#50A932',
                };
            case 'duplicate':
                return {
                    width: 110,
                    label: t('DUPLICATE'),
                    color: 'blue',
                };
            case 'confirmModal':
                return {
                    width: 180,
                    label: t('CONFIRM'),
                    color: 'green',
                };
            case 'saveModal':
                return {
                    width: 180,
                    label: t('SAVE'),
                    color: 'green',
                };
            case 'closeModal':
                return {
                    width: 100,
                    label: t('CLOSE'),
                    color: 'grey',
                };
            case 'connectionTest':
                return {
                    width: 300,
                    label: t('CONNECTION_TEST'),
                    color: 'color',
                };
            case 'blacklisted':
            case 'certnotissued':
                return {
                    width: 300,
                    color: 'grey',
                    label: t('NOT_ALLOWED'),
                };
            case 'roleActivate':
                return {
                    width: 130,
                    color: 'blue',
                    label: t('ACTIVATE'),
                };
            case 'roleDeactivate':
                return {
                    width: 130,
                    color: 'red',
                    label: t('DEACTIVATE'),
                };
            case 'userActivate':
                return {
                    width: 130,
                    color: 'blue',
                    label: t('ACTIVATE'),
                };
            case 'userDeactivate':
                return {
                    width: 130,
                    color: 'red',
                    label: t('DEACTIVATE'),
                };
            case 'open':
                return {
                    width: 190,
                    color: 'blue',
                    label: t('OPEN_LOCK'),
                };
            case 'detail':
                return {
                    width: 80,
                    label: t('DETAIL'),
                };
            case 'mdmActivate':
                return {
                    width: 130,
                    color: 'blue',
                    label: t('ACTIVATE'),
                };
            case 'mdmDeactivate':
                return {
                    width: 130,
                    color: 'red',
                    label: t('DEACTIVATE'),
                };
            case 'continue':
                return {
                    width: 160,
                    color: 'green',
                    label: t('CONTINUE'),
                };
            default:
                return {};
        }
    };

    if (action === '') {
        return null;
    }

    return (
        <CustomButton
            className={className}
            label={capitalize(action)}
            disabled={disabled}
            {...getProps(action)}
            onClick={handleClick}
            muiIcon={muiIcon}
        />
    );
}

ActionButton.propTypes = {
    className: PropTypes.string,
    action: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
};

ActionButton.defaultProps = {
    className: '',
    action: 'create',
    disabled: false,
    handleClick: null,
};

export default ActionButton;
