import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {logout} from '../../redux/actions/auth';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import logo from '../../img/logo_hopi_cz.svg';
import LeftDrawer from './LeftDrawer';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import BreadCrumbs from './BreadCrumbs'
import SearchIcon from '@mui/icons-material/Search';
import { registerPushNotification } from "../../redux/actions/users";


const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 120,
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: theme.palette.background.dark,
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        height: 90,
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        color: theme.palette.base['white'],
    },
    divlogo: {
        marginTop: '1px',
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
    },
    username: {
        textAlign: 'right',
        paddingTop: '20px'
    },
    signOut: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '16px',
        color: '#C8C8C8',
        textTransform: 'none',

        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.base[200],
        },
    },
    searchIcon: {
        height: '100%',
        // paddingTop: '20px',
        // paddingBottom: '20px',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginLeft: '40px',
    },
    inputRoot: {
        color: 'inherit',
        backgroundColor: 'white',
        borderRadius: '30px',
        height: '30px',
        width: '100%'
    },
    breadCrumbs: {
        backgroundColor: theme.palette.background.paper
    }
}));


const TopBar = (props) => {
    const {user, userUseCases, handleFulltextOpen, registerPushNotification} = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const [token, setToken] = useState(null);

    const getToken = () => {
        const kcToken = localStorage.getItem('kc_token');
        setToken(kcToken);
    };

    // This will register push notification, if enabled
    useEffect(() => {
        if (token) {
            registerPushNotification();
        } else {
            getToken();
        }
    }, [token]);

    return (
        <AppBar position="absolute" className={classes.AppBar} elevation={0}>
            <Toolbar className={classes.toolbar}>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <div className={classes.divlogo}>
                            <LeftDrawer userUseCases={userUseCases}/>
                            <div style={{marginLeft:'30px'}}>
                            <img src={logo} width={90}/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3} >
                        <div className={classes.searchIcon}>
                            <TextField
                                variant="outlined"                                
                                onClick={handleFulltextOpen}
                                classes={{root: classes.inputRoot}}
                                sx={{border: 'none',"& fieldset": { border: 'none' },}}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton style={{marginTop: '-25px'}}><SearchIcon/></IconButton>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                        {user && (
                            <div className={classes.username}>
                                <Typography noWrap variant="body1">
                                    { user.lastName &&  user.firstName && `${user.firstName} ${user.lastName}` }
                                    { user.lastName &&  !user.firstName && `${user.lastName}` }
                                    { !user.lastName && user.username && `${user.username}` }
                                </Typography>
                                <Button
                                    color="primary"
                                    variant="text"
                                    className={classes.signOut}
                                    onClick={props.logout}
                                >
                                    {t('SIGN_OUT')}
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
            <div className={classes.breadCrumbs}>
                <BreadCrumbs />
            </div>
        </AppBar>
    )
}

TopBar.propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
};

TopBar.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
    registerPushNotification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
