import httpService from './http.service';


const finishSecurityCheck = (securityCheckId) => httpService
    .post(`/securitychecks/${securityCheckId}/finish?manual=true`, {})
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

export default {
    finishSecurityCheck
};