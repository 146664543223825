import React, { useEffect, useState } from "react";
import { CustomTable, Loading } from '../../components/elements';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import store from '../../redux/store';
import { initializeSseSecurityCheckES } from '../../services/securityCheck.sse';
import { useParams } from "react-router-dom";
import { EMITTER_TYPE_SECURITY_CHECK } from "../../utils/constants";
import { Checkbox, FormControlLabel, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns'
import { ActionButton } from "../../components/common";
import { finishSecurityCheck } from "../../redux/actions/securityCheck";
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '850px',
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
    description: {
        color: 'rgba(0, 0, 0, 0.9)',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '7px',
    },
    roleBox1: {
        marginLeft: '15px',
        marginTop: '15px',
        border: '1px solid #606060',
        borderRadius: '15px',
        padding: '15px'
    },
    roleBox2: {
        marginLeft: '15px',
        marginTop: '15px',
        border: '1px solid #606060',
        borderRadius: '15px',
        padding: '15px'
    },
    roleBox3: {
        // marginLeft: '15px',
        // marginTop: '15px',
        // border: '1px solid #606060', 
        // borderRadius: '15px',
        // padding: '15px'
    },
    previewImage: {
        marginTop: '20px',
        width: '95%'
    },
    checkButton: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 16,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
        color: 'white'
    },
    terminateButton: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 16,
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
        color: 'black'
    },
}));

const CameraImage = ({image}) => {
    const classes = useStyles();
    return (
        image ?
            <img src={'data:image/jpg;base64,' + image}
                className={classes.previewImage}
            /> :
            <Skeleton className={classes.previewImage} />
    );
}

const SecurityCheck = (props) => {
    const {
        user,
        userUseCases,
        sseEvent,
        finishSecurityCheck,
    } = props;

    const { t } = useTranslation();

    const classes = useStyles();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const { checkpointId } = useParams();
    const [formValues, setFormValues] = useState({ name: '', });
    const [securityCheck, setSecurityCheck] = useState(null);

    const getToken = () => {
        const kcToken = localStorage.getItem('kc_token');
        setToken(kcToken);
    };

    useEffect(() => {
        getToken();
    });

    useEffect(() => {
        if (token) {
            initializeSseSecurityCheckES(token, store, setToken, EMITTER_TYPE_SECURITY_CHECK, checkpointId);
        }
    }, [token]);

    useEffect(() => {
        console.log('sseEvent', sseEvent);
        const secCheck = sseEvent.securityCheck;
        if (secCheck && secCheck?.checkpointId == checkpointId) {
            console.log('secCheck', secCheck);
            setFormValues(
                {
                    ...formValues,
                    registrationNumber: secCheck.qtirId,
                    lpnFront: secCheck.frontLicensePlate,
                    lpnMiddle: secCheck.midLicensePlate,
                    lpnBack: secCheck.backLicensePlate
                });
            setSecurityCheck(secCheck);
        }
    }, [sseEvent]);

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            if (name === 'seal') {
                setFormValues({ ...formValues, [name]: event.target.value === 'on' ? true : false });
            } else {
                setFormValues({ ...formValues, [name]: event.target.value });
            }
        } else {
            setFormValues({ ...formValues, [name]: event });
        }
    }

    const handleCheck = () => {
        finishSecurityCheck(securityCheck.securityCheckId).then(() => navigate(`/${process.env.REACT_APP_PATH}/checkpoint`));
    }

    const handleTerminate = () => {
        finishSecurityCheck(securityCheck.securityCheckId).then(() => navigate(`/${process.env.REACT_APP_PATH}/checkpoint`));
    }

    if (!user) {
        return (
            <Loading />
        );
    }

    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
        },
        paging: false
    };
    const cellStyle = {
        paddingLeft: 4,
        paddingRight: 4,
        lineHeight: '19px',
        color: '#1B1B28',
    };

    const columns = [
        {
            title: t('STATE'),
            field: 'state',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                t(rowData.state)
            )
        },
        {
            title: t('DATE'),
            field: 'actualDate',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'actualDate', paddingLeft: 5
            },
            render: (rowData) => (
                format(new Date(rowData.actualDate), 'dd.MM.yyyy hh:mm:ss')
            )
        }
    ]

    return (
        <div className={classes.container}>
            <PerfectScrollbar
                options={{
                    suppressScrollX: true,
                    minScrollbarLength: 50,
                }}
            >
                {securityCheck ?
                    <Grid container columnSpacing={4} rowSpacing={1}>
                        <Grid item sm={2}>
                            <Typography variant="h6" className={classes.description}>
                                {securityCheck.checkpointName}
                            </Typography>
                        </Grid>
                        <Grid item sm={10}></Grid>

                        <Grid item sm={12}></Grid>
                        <Grid item sm={12}></Grid>
                        <Grid item sm={12}></Grid>

                        <Grid item sm={2}>
                            <Typography variant="h6">
                                {securityCheck.isCheckRunning &&
                                    <>{t('CHECK_RUNNING')}</>
                                }
                                {!securityCheck.isCheckRunning &&
                                    <>{t('CHECK_NOT_RUNNING')}</>
                                }
                            </Typography>
                            <Grid container spacing={1} className={classes.roleBox1}>
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    fullWidth
                                    id="name"
                                    value={formValues.registrationNumber || ''}
                                    onChange={handleChange('registrationNumber', false)}
                                    label={t('REGISTRATION_NUMBER')}
                                />
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    fullWidth
                                    required
                                    id="lpnFront"
                                    value={formValues.lpnFront || ''}
                                    onChange={handleChange('lpnFront', false)}
                                    label={t('LPN_FRONT')}
                                />
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    fullWidth
                                    id="lpnMiddle"
                                    value={formValues.lpnMiddle || ''}
                                    onChange={handleChange('lpnMiddle', false)}
                                    label={t('LPN_MIDDLE')}
                                />
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    fullWidth
                                    id="lpnBack"
                                    value={formValues.lpnBack || ''}
                                    onChange={handleChange('lpnBack', false)}
                                    label={t('LPN_BACK')}
                                />

                                <FormControlLabel control={<Checkbox checked={true} onChange={handleChange('seal', false)} />} label={t('CAR_WITH_SEAL')} />
                            </Grid>
                        </Grid>
                        <Grid item sm={3}>
                            <Typography variant="h6">
                                &nbsp;
                            </Typography>
                            <Grid container spacing={1} className={classes.roleBox2}>
                                {securityCheck.history &&
                                    <CustomTable
                                        title=""
                                        columns={columns}
                                        data={securityCheck.history}
                                        options={tableOptions}
                                        loadAllData={false}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid item sm={7}>
                            <Grid container rowSpacing={5} className={classes.roleBox3}>
                                <Grid item sm={4}>
                                    <Typography variant="h6">
                                        {t('CAMERA_FRONT')}
                                    </Typography>
                                    <CameraImage image={securityCheck.cameraFront} />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography variant="h6">
                                        {t('CAMERA_MIDDLE')}
                                    </Typography>
                                    <CameraImage image={securityCheck.cameraMid} />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography variant="h6">
                                        {t('CAMERA_BACK')}
                                    </Typography>
                                    <CameraImage image={securityCheck.cameraBack} />
                                </Grid>

                                <Grid item sm={4}>
                                    <Typography variant="h6">
                                        {t('CAMERA_LEFT')}
                                    </Typography>
                                    <CameraImage image={securityCheck.cameraLeft} />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography variant="h6">
                                        {t('CAMERA_RIGHT')}
                                    </Typography>
                                    <CameraImage image={securityCheck.cameraRight} />
                                </Grid>
                                <Grid item sm={4}>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} >
                            <ActionButton action={'check'} className={classes.checkButton} handleClick={() => { handleCheck() }} />
                            <ActionButton action={'terminate'} className={classes.terminateButton} handleClick={() => { handleTerminate() }} />
                        </Grid>
                        <Grid item sm={9}>
                        </Grid>
                    </Grid>
                    :
                    <Loading />
                }
            </PerfectScrollbar>
        </div>
    );

}

SecurityCheck.propTypes = {
    user: PropTypes.object,
};

SecurityCheck.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    sseEvent: store.securityCheckData.securityCheck
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    finishSecurityCheck
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SecurityCheck);
