import cameraConfigurationService from '../../services/cameraConfiguration.service';
import types from '../actionTypes';


export const createCameraConfiguration = (cameraConfiguration) => (dispatch, getState) => {
    if (getState().cameraConfigurationData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CAMERACONFIGURATION_CREATE_REQUEST,
    });

    return cameraConfigurationService.createCameraConfiguration(cameraConfiguration)
        .then((cameraConfiguration) => {
            dispatch({
                type: types.CAMERACONFIGURATION_CREATE_SUCCESS,
                payload: {cameraConfiguration: cameraConfiguration},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CAMERACONFIGURATION_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateCameraConfiguration = (cameraConfiguration) => (dispatch, getState) => {
    if (getState().cameraConfigurationData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CAMERACONFIGURATION_UPDATE_REQUEST,
    });

    return cameraConfigurationService.updateCameraConfiguration(cameraConfiguration)
        .then((cameraConfiguration) => {
            dispatch({
                type: types.CAMERACONFIGURATION_UPDATE_SUCCESS,
                payload: {cameraConfiguration: cameraConfiguration},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CAMERACONFIGURATION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const testCameraConfiguration = (cameraConfiguration) => (dispatch, getState) => {
    if (getState().cameraConfigurationData.isTesting) {
        return Promise.reject();
    }

    dispatch({
        type: types.CAMERACONFIGURATION_TEST_REQUEST,
    });

    return cameraConfigurationService.testCameraConfiguration(cameraConfiguration)
        .then((cameraConfiguration) => {
            dispatch({
                type: types.CAMERACONFIGURATION_TEST_SUCCESS,
                payload: {cameraConfiguration: cameraConfiguration},
            });
            return cameraConfiguration;
        })
        .catch((error) => {
            dispatch({
                type: types.CAMERACONFIGURATION_TEST_FAIL,
                payload: {error},
            });

            throw error;
        });
};

