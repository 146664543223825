import { EncryptionFactory } from './encryption/encryption-factory.js';
import { VAPID_KEYS } from './vapid-keys.js';
import { PushClient } from './push-client.js';
import { VapidHelper2 } from './encryption/vapid-helper-2.js';

export class PushRegistration {
    constructor() {
        console.log("Creating push notification subscription");

        this._encryptionHelper = EncryptionFactory.generateHelper();
        this._stateChangeListener = this._stateChangeListener.bind(this);
        this._subscriptionUpdate = this._subscriptionUpdate.bind(this);

        this._pushClient = new PushClient(
            this._stateChangeListener,
            this._subscriptionUpdate,
            VAPID_KEYS.publicKey,
        );

        this._pushClient.subscribeDevice();
        // this._pushClient.unsubscribeDevice();
    }

    _stateChangeListener(state, data) {
        console.log(`The status of the PushSubscription is`, state, data);
    }

    _subscriptionUpdate(subscription) {
        this._currentSubscription = subscription;
        if (!subscription) {
            // Remove any subscription from your servers if you have
            // set it up.
            console.log("Subscription removed", subscription);
            return;
        }

        // This is too handle old versions of Firefox where keys would exist
        // but auth wouldn't
        const subscriptionObject = JSON.parse(JSON.stringify(subscription));
        if (
            subscriptionObject &&
            subscriptionObject.keys &&
            subscriptionObject.keys.auth &&
            subscriptionObject.keys.p256dh) {
        }

        this.updatePushInfo();
    }

    async updatePushInfo() {
        // Let's look at payload
        //const vapidHeaders = await VapidHelper2.createVapidAuthHeader(VAPID_KEYS, this._currentSubscription.endpoint, "https://www.scrumware.eu");
        const key = this._currentSubscription.getKey ? this._currentSubscription.getKey('p256dh') : '';
        const auth = this._currentSubscription.getKey ? this._currentSubscription.getKey('auth') : '';

        const subscription = {
            endpoint: this._currentSubscription.endpoint,
            key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
            auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''
        }

        localStorage.setItem("PUSH_SUBSCRIPTION", JSON.stringify(subscription));
    }
}
