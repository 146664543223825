import React from "react";
import './App.css';
import { styled } from '@mui/system';
import { TopBar } from './components/layout';
import Adminstration from './containers/administration';
import { Navigate, Routes, Route } from 'react-router-dom';
import "@fontsource/titillium-web/200.css";
import "@fontsource/titillium-web/400.css";
import "@fontsource/titillium-web/600.css";
import "@fontsource/titillium-web/700.css";
import "@fontsource/titillium-web/900.css";
import RoleAdministration from "./containers/administration/Role";
import UserAdministration from "./containers/administration/User";
import DistributionCenter from "./containers/administration/DistributionCenter";
import CheckpointAdmin from "./containers/administration/Checkpoint";
import Checkpoint from "./containers/checkPoint";
import SecurityCheck from './containers/securityCheck'

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
}));

const AppBarSpacer = styled('div')(({ theme }) => ({
  height: 150,
  //...theme.mixins.toolbar,
}));

const AppContent = styled('div')(({ theme }) => ({
  height: 'calc(100% - 70px)',
  backgroundColor: theme.palette.background.paper,
  paddingTop: 0,
  '& > .scrollbar-container': {
  },
}));

function App() {
  return (
    <div className="App">
      <RootDiv>
        <TopBar />
        <MainContent>
          <AppBarSpacer />
          <AppContent>
            <Routes >
              <Route path={`/${process.env.REACT_APP_PATH}`} element={<Navigate to={`/${process.env.REACT_APP_PATH}/administration`} />} />
              <Route exact path={'/'} element={<Adminstration />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/administration`} element={<Adminstration />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/administration/rolelist`} element={<RoleAdministration />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/administration/userlist`} element={<UserAdministration />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/administration/distributioncenterlist`} element={<DistributionCenter />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/administration/checkpointlist`} element={<CheckpointAdmin />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/checkpoint`} element={<Checkpoint />} />
              <Route exact path={`/${process.env.REACT_APP_PATH}/checkpoint/securitycheck/:checkpointId`} element={<SecurityCheck />} />
            </Routes>
          </AppContent>
        </MainContent>
      </RootDiv>
    </div>
  );
}

export default App;

