import {combineReducers} from 'redux';

import authData from './auth';
import codelistData from './codelist';
import rolesData from './roles';
import usersData from './users';
import useCasesData from './usecases';
import codesData from './codelist';
import centersData from './distributionCenters';
import checkpointsData from './checkpoints';
import cameraConfigurationData from './cameraConfiguration';
import securityCheckData from './securityCheck';


export default () => combineReducers({
    authData,
    codelistData,
    rolesData,
    usersData,
    useCasesData,
    codesData,
    centersData,
    checkpointsData,
    cameraConfigurationData,
    securityCheckData
});
