import centerService from '../../services/distributionCenter.service';
import types from '../actionTypes';


export const createDistributionCenter = (center) => (dispatch, getState) => {
    if (getState().centersData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CENTER_FETCH_REQUEST,
    });

    return centerService.createDistributionCenter(center)
        .then((center) => {
            dispatch({
                type: types.CENTER_FETCH_SUCCESS,
                payload: {center},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CENTER_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateDistributionCenter = (center) => (dispatch, getState) => {
    if (getState().centersData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CENTER_UPDATE_REQUEST,
    });

    return centerService.updateDistributionCenter(center)
        .then((center) => {
            dispatch({
                type: types.CENTER_UPDATE_SUCCESS,
                payload: {center},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CENTER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateCenterState = (centerId, active) => (dispatch, getState) => {
    if (getState().centersData.isStateUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CENTER_STATE_REQUEST,
    });

    return centerService.updateState(centerId, active)
        .then((center) => {
            dispatch({
                type: types.CENTER_STATE_SUCCESS,
                payload: {center: center},
            });
            return center;
        })
        .catch((error) => {
            dispatch({
                type: types.CENTER_STATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllDistributionCenters = () => (dispatch, getState) => {
    if (getState().centersData.isFetchingAllCenters) {
        return Promise.reject();
    }

    dispatch({
        type: types.CENTER_FETCH_REQUEST,
    });

    return centerService.getAllDistributionCenters()
        .then((centers) => {
            dispatch({
                type: types.CENTER_FETCH_SUCCESS,
                payload: { centers },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CENTER_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
