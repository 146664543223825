import httpService from './http.service';

const getUsersAll = () => httpService
    .get(`/user/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
const getUsersAllByRole = (roleId) => httpService
    .get(`/user/all/role/${roleId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createUser = (user) => httpService
    .post(`/user/create`, user)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateUser = (user) => httpService
    .put(`/user/update`, user)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateUserState = (userId, active) => httpService
    .put(`/user/${userId}?active=${active}`)
    .then()
    .catch((err) => Promise.reject(err));

const registerPushNotification = (subscriptionEndpoint) => httpService
    .post(`/user/registerPushNotification`, subscriptionEndpoint)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    getUsersAll,
    getUsersAllByRole,
    createUser,
    updateUser,
    updateUserState,
    registerPushNotification,
};
