import httpService from './http.service';


const createCameraConfiguration = (cameraConfiguration) => httpService
    .post(`/cameraconfiguration`, cameraConfiguration)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateCameraConfiguration = (cameraConfiguration) => httpService
    .put(`/cameraconfiguration`, cameraConfiguration)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const testCameraConfiguration = (cameraConfiguration) => httpService
    .put(`/cameraconfiguration/test`, cameraConfiguration)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    createCameraConfiguration, updateCameraConfiguration, testCameraConfiguration
};
