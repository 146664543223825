import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {ActionButton} from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import RoleDetail from "./RoleDetail";
import RoleTable from "./RoleTable";
import {fetchAllUsersByRole} from "../../../redux/actions/users";
import {CustomSearchInput} from '../../elements'
import { Grid, Typography } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            borderRadius: '20px',
        },
        height: '100%',
        // border: 'groove',
    },
    search: {
        paddingTop: '10px',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 800,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    titleText: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        marginLeft: '-15px',
        paddingLeft: '15px',
    },
    titleText2: {
        color: theme.palette.base[400],
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    button2: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    
}));


function Role(props) {
    const {
        userUseCases,
        useCases,
        handleClose,
        readOnly,
        roleConfigModel,
        mode,
        setMode,
        handleSearch,
        fetchAllUsersByRole,
        roleUsers,
        allUsers,
        searchMenuItemClick,
        handleRemoveUser,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [roleDetail, setRoleDetail] = useState();
    const [users, setUsers] = useState();
    const [isUsersSeted, setIsUsersSeted] = useState(false);
    const [errorStep1, setErrorStep1] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('error');
    const [isFetchedRoleUser, setIsFetchedRoleUser] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isFetchedRoleUser && roleDetail && roleDetail.roleId) {
            setIsLoading(true);
            fetchAllUsersByRole(roleDetail.roleId).then(() => {
                setIsFetchedRoleUser(true);
                setIsLoading(true);
            }).catch((err) => {
                setIsFetchedRoleUser(false);
                setIsLoading(false)
            });
        }
    }, [isFetchedRoleUser, fetchAllUsersByRole, roleDetail]);

    useEffect(() => {
        if (roleConfigModel.role) {
            setRoleDetail(roleConfigModel.role);
            setIsFetchedRoleUser(false);
        }
        setErrorStep1({});
        setSeverity("error");
        setSnackbarOpen(false);
        setSnackbarMessage('');
    }, [roleConfigModel]);

    useEffect(() => {
        if ( allUsers ) {
            let tmp = allUsers.filter((item) => {
                for ( let i=0; i<roleUsers.length; i++ ) {
                    if ( roleUsers[i].id===item.id )
                        return false;
                }
                return true;
            });

            setUsers(tmp);
            setIsUsersSeted(true);
        }
    }, [allUsers, isUsersSeted])

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const searchMenuItemClickLocal = (userId) => {
        searchMenuItemClick(userId, roleDetail.roleId);
        setIsFetchedRoleUser(false);
        setIsUsersSeted(false);
    }
    
    const handleRemoveUserLocal = (user) => {
        handleRemoveUser(user.id, roleDetail.roleId);
        setIsFetchedRoleUser(false);
        setIsUsersSeted(false);
    }
    
    const searchMenuData = users && users.length > 0
    ? users.map((data) => ({
        key: data.id,
        label: `${data.firstName} ${data.lastName}`,
        value: data.id,
        }))
    : [];

    const setActiveStepHandle = (mode) => () => {
        setMode(mode);
    }

    return (
        <Dialog
            className={classes.container}
            open={roleConfigModel.open}
            onClose={handleClose}
        >
            <DialogTitle> 
                <div className={classes.title}>
                    <Grid container >
                        <Grid item xs={1} className={mode===STEP_ONE?classes.titleText:classes.titleText2}>
                            <div onClick={setActiveStepHandle(STEP_ONE)}>{t('ROLE_DETAIL')}</div>
                        </Grid>
                        <Grid item xs={1} className={mode===STEP_TWO?classes.titleText:classes.titleText2}>
                            <div onClick={setActiveStepHandle(STEP_TWO)}>{t('USER_ASSIGNED')}</div>
                        </Grid>
                        <Grid item xs={10}/>
                    </Grid>
                </div>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={severity} message={snackbarMessage}/>

            {mode===STEP_ONE &&
                <>
                <DialogContent className={classes.content}>
                    <div className={classes.container}>
                        <RoleDetail 
                            userUseCases={userUseCases}
                            useCases={useCases}
                            updatedRole={roleDetail}
                            error={errorStep1}
                            readOnly={readOnly}
                            setSnackbarError={setSnackbarMessage}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div className={classes.buttonNavigation}>
                        <div className={classes.wrapper}>
                            <ActionButton
                                action="close"
                                handleClick={handleClose}
                                className={classes.button}
                            />
                        </div>
                    </div>
                </DialogActions>
                </>
            }
            {mode===STEP_TWO &&
                <>
                <DialogContent className={classes.content}>
                    <div className={classes.container}>
                        <div className={classes.search}>
                            <CustomSearchInput
                                placeholder={t('USER_NAME')}
                                menuData={searchMenuData}
                                menuItemClick={searchMenuItemClickLocal}
                                handleChange={handleSearch}
                                showIcon={false}
                            />
                        </div>

                        <RoleTable 
                            userUseCases={userUseCases}
                            useCases={useCases}
                            updatedRole={roleDetail}
                            roleUsers={roleUsers}
                            error={errorStep1}
                            readOnly={readOnly}
                            setSnackbarError={setSnackbarMessage}
                            setSnackbarOpen={setSnackbarOpen}
                            handleRemoveUser={handleRemoveUserLocal}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div className={classes.buttonNavigationCancel}>
                        <div className={classes.wrapper}>
                            <ActionButton
                                action="close"
                                handleClick={handleClose}
                                className={classes.button}
                            />
                        </div>
                    </div>
                </DialogActions>
                </>
            }
        </Dialog>

    );
}

const STEP_ONE = 'STEP_1';
const STEP_TWO = 'STEP_2';

Role.propTypes = {};

const mapStateToProps = (store) => ({
    roleUsers: store.usersData.roleUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllUsersByRole,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Role);


