import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAllCenters : false,
  isStateUpdating: false,
  isCreating: false,
  isUpdating: false,
  centers: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CENTER_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAllCenters: true,
      };
    case types.CENTER_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllCenters: false,
        centers: action.payload.centers,
      };
    case types.CENTER_FETCH_FAIL:
      return {
        ...state,
        isFetchingAllCenters: false,
        errors: action.payload.error,
      };

    case types.CENTER_STATE_REQUEST:
      return {
        ...state,
        isStateUpdating: true,
      }
    case types.CENTER_STATE_SUCCESS:
      return {
        ...state,
        isStateUpdating: false,
      };
    case types.CENTER_STATE_FAIL:
      return {
        ...state,
        isStateUpdating: false,
        errors: action.payload.error,
      };

    case types.CENTER_CREATE_REQUEST:
      return {
          ...state, 
          isCreating: true
      };
    case types.CENTER_CREATE_SUCCESS:
      return {
          ...state,
          isCreating: false
      };
    case types.CENTER_CREATE_FAIL:
      return {
          ...state, 
          isCreating: false,
          errors: action.payload.error,
      };

    case types.CENTER_UPDATE_REQUEST:
      return {
          ...state, 
          isUpdating: true
      };
    case types.CENTER_UPDATE_SUCCESS:
      return {
          ...state,
          isUpdating: false
      };
    case types.CENTER_UPDATE_FAIL:
      return {
          ...state, 
          isUpdating: false,
          errors: action.payload.error,
      };

    default:
      return state;
  }
};
