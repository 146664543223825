import React, { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { CustomTable, Loading } from '../../../components/elements';
import { connect } from 'react-redux';
import { ActionButton } from "../../../components/common";
import { getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import PlusIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';
import PDSDialog from '../../../components/common/PDSDialog';
import { UC0402, UC0403, UC0404 } from '../../../utils/constants';
import { Grid, useTheme } from '@mui/material';
import ActivityState from '../../../components/common/ActivityState';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 24,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    centerCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.blue,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    centerCardDeactivate: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.disabledGray,
        cursor: 'pointer',
        borderRadius: '15px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%',
        textAlign: 'center',
    },
    root: {
        height: 54,
        backgroundColor: theme.palette.background.azure,
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        paddingTop: 6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rootDeactivate: {
        height: 54,
        backgroundColor: theme.palette.background.darkGrey,
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        paddingTop: 6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    state: {
        display: 'flex',
    },
    row1: {
        padding: 16,
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: 25,
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.background.green,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.background.red,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.base.black,
        minWidth: '50%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 14,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 14,
        textAlign: 'center',
    },
    value2: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function DistributionCenterList(props) {
    const classes = useStyles();
    const { palette } = useTheme();
    const { t } = useTranslation();
    const {
        centers,
        userUseCases,
        isLoading,
        handleCenterDetail,
        handleCenterUpdate,
        handleActivate,
        save,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
        confirmModalOpened,
        setConfirmModalOpened,
        table,
    } = props;

    const [centerToActive, setCenterToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const saveInner = (center) => {
        setActionInProgress(true);
        save(center).then(
            () => {
                refresh();
                setActionInProgress(false);
            }
        ).catch((error) => {
            setDeletionSnackbarError(getErrorMessage(error, t));
            setActionInProgress(false);
        });
    }

    const columns = [
        {
            title: t('STATE'),
            field: 'active',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                <ActivityState active={rowData?.active} />
            )
        },
        {
            title: t('DISTRIBUTION_CENTER_NAME'),
            field: 'name',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0404, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleCenterDetail(rowData) }}
                            disabled={!isComponentEnabledForUser(UC0404, userUseCases) || actionInProgress}
                            className={classes.green2}
                            action="detail"
                        />
                    }
                    {isComponentVisibleForUser(UC0402, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleCenterUpdate(rowData, false) }}
                            disabled={!isComponentEnabledForUser(UC0402, userUseCases) || actionInProgress}
                            className={classes.red2}
                            action="edit"
                        />
                    }
                    {isComponentVisibleForUser(UC0403, userUseCases) &&
                        <ActionButton
                            handleClick={() => {
                                setCenterToActive(rowData);
                                setConfirmModalOpened(true);
                            }}
                            disabled={!isComponentEnabledForUser(UC0403, userUseCases) || actionInProgress}
                            className={classes.blue2}
                            action={rowData.active ? t('DEACTIVATE') : t('ACTIVATE')}
                        />
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (
                    <PDSDialog
                        open={confirmModalOpened}
                        title={`${centerToActive.active ? t('DISTRIBUTION_CENTER_INACTIVATION') : t('DISTRIBUTION_CENTER_ACTIVATION')}`}
                        onClose={() => {
                            setConfirmModalOpened(false);
                            setCenterToActive(null);
                        }}
                        size="md"
                        headerColor={centerToActive.active ? palette.primary.red : palette.primary.blue}
                        height={450}
                        onSubmit={() => { handleActivate(centerToActive); }}
                        actionType={centerToActive.active ? 'roleDeactivate' : 'roleActivate'}
                        showCloseButton={false}
                        showCheckIcon={false}
                    >

                        <div className={classes.content}>
                            {centerToActive.active ? t('CONFIRM_DISTRIBUTION_CENTER_DEACTIVE', { name: centerToActive.name }) : t('CONFIRM_DISTRIBUTION_CENTER_ACTIVE', { name: centerToActive.name })}
                        </div>
                    </PDSDialog>
                )}


            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={centers}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                /> :
                    centers.map((centerItem, index) => (
                        <div className={centerItem?.active ? classes.centerCard : classes.centerCardDeactivate} key={centerItem.distributionCenterId}>
                            <Grid container className={classes.row1} >
                                <Grid item xs={2} >
                                    <ActivityState active={centerItem?.active} />
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid
                                    item xs={4}
                                    container
                                    direction="column"
                                    alignItems="flex-end"
                                    justify="flex-start"
                                >
                                    {isComponentVisibleForUser(UC0402, userUseCases) &&
                                        <Tooltip title={t('EDIT')}>
                                            <EditIcon
                                                fontSize='large'
                                                onClick={() => {
                                                    handleCenterUpdate(centerItem, false)
                                                }}
                                                disabled={!isComponentEnabledForUser(UC0402, userUseCases)}
                                                style={{ fontSize: 30, marginTop: -6 }}
                                            />
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                            <div className={classes.row2}>
                                <div className={classes.title}>
                                    <Tooltip title={t('NAME')}>
                                        <Typography variant="h2" style={{ fontWeight: 600 }}>{centerItem.name}</Typography>
                                    </Tooltip>
                                </div>
                            </div>
                            <Grid container className={centerItem?.active ? classes.root : classes.rootDeactivate} >
                                <Grid item xs={6}>
                                    {isComponentVisibleForUser(UC0404, userUseCases) &&
                                        <Tooltip title={t('SHOW_DISTRIBUTION_CENTER_DETAIL')}>
                                            <InfoIcon
                                                fontSize='large'
                                                disabled={actionInProgress || !isComponentEnabledForUser(UC0404, userUseCases)}
                                                onClick={() => { handleCenterDetail(centerItem) }}
                                                style={{ fontSize: 30 }}
                                            />
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {isComponentVisibleForUser(UC0403, userUseCases) &&
                                        <Tooltip title={centerItem.active ? t('SHOW_DISTRIBUTION_CENTER_DEACTIVE') : t('SHOW_DISTRIBUTION_CENTER_ACTIVE')}>
                                            {centerItem.active === true ?
                                                <CancelIcon
                                                    fontSize='large'
                                                    disabled={actionInProgress || !isComponentEnabledForUser(UC0403, userUseCases)}
                                                    onClick={() => {
                                                        setCenterToActive(centerItem);
                                                        setConfirmModalOpened(true);
                                                    }}
                                                    style={{ fontSize: 30 }}
                                                /> :
                                                <PlusIcon
                                                    fontSize='large'
                                                    disabled={actionInProgress || !isComponentEnabledForUser(UC0403, userUseCases)}
                                                    onClick={() => {
                                                        setCenterToActive(centerItem);
                                                        setConfirmModalOpened(true);
                                                    }}
                                                    style={{ fontSize: 30 }}
                                                />
                                            }
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    ))
                :
                <Loading />
            }
        </div>
    );
}

DistributionCenterList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistributionCenterList);