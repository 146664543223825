import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import { CircularProgress, Grid } from "@mui/material";
import BasicSetting from './BasicSetting';
import CameraSetting from './CameraSetting';
import { STEP_FIVE, STEP_FOUR, STEP_ONE, STEP_SEVEN, STEP_SIX, STEP_THREE, STEP_TWO } from '../../../utils/constants';
import { getCameraCode } from '../../../utils';


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 955,
            minHeight: '90%',
            maxWidth: '100%',
            borderRadius: '20px',
            overflowX: 'hidden',
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600,
        borderBottom: `1px solid ${theme.palette.base[600]}`,
        marginLeft: '-25px',
        paddingLeft: '25px',
        marginRight: '-25px',
        paddingRight: '25px',
    },
    titleText: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    titleText2: {
        color: theme.palette.base[500],
        cursor: 'pointer',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    titleTextDisabled: {
        color: theme.palette.base[300],
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
    },
    button: {
        width: 140,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        color: theme.palette.base[800],
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
    },
    button2: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '15px',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));


function Checkpoint(props) {
    const {
        user,
        userUseCases,
        useCases,
        handleClose,
        readOnly,
        saveCheckpoint,
        checkpointConfigModel,
        error,
        setError,
        saving,
        setSaving,
        snackbarOpen,
        setSnackbarOpen,
        setMode,
        mode,
        handleCameraTest,
    } = props;


    const classes = useStyles();

    const { t } = useTranslation();


    const [updatedCheckpoint, setUpdatedCheckpoint] = useState(
        {
            lat: '50.05193',
            lng: '14.42686',
            line: {
                startX: 0,
                endX: 1.0,
                startY: 0.66,
                endY: 0.66,
            }
        });
    const [errorStep1, setErrorStep1] = useState({});

    useEffect(() => {
        if (checkpointConfigModel.checkpoint) {
            setUpdatedCheckpoint(checkpointConfigModel.checkpoint);
        }
        setErrorStep1({});
    }, [checkpointConfigModel]);

    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedCheckpoint.name || updatedCheckpoint.name.trim().length === 0) {
            error.name = 'required';
            ret = false;
        }
        if (!updatedCheckpoint.active) {
            error.active = 'required';
            ret = false;
        }
        // console.log('error', error, updatedCheckpoint);
        setErrorStep1(error);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedCheckpoint({ ...updatedCheckpoint, [name]: event.target.value });
        } else {
            setUpdatedCheckpoint({ ...updatedCheckpoint, [name]: event });
        }
    }

    const handleCameraChange = (name, camIndex) => (event) => {
        const cameraCodes = getCameraCode(mode);
        const tmp = [];
        let finded = false;
        if (updatedCheckpoint.cameraConfiguration && updatedCheckpoint.cameraConfiguration.length > 0) {
            for (let i = 0; i < updatedCheckpoint.cameraConfiguration.length; i++) {
                if (cameraCodes != null && cameraCodes.length > 0 && updatedCheckpoint.cameraConfiguration[i].cameraType === cameraCodes[camIndex]) {
                    tmp.push({ ...updatedCheckpoint.cameraConfiguration[i], [name]: event.target.value });
                    finded = true;
                } else {
                    tmp.push({ ...updatedCheckpoint.cameraConfiguration[i] });
                }
            }
        }
        if (!finded) {
            tmp.push({ cameraType: cameraCodes[camIndex], checkpointId: updatedCheckpoint.checkpointId, [name]: event.target.value });
        }
        setUpdatedCheckpoint({ ...updatedCheckpoint, cameraConfiguration: tmp });
    }

    const handleCameraFullChange = (camIndex) => (updatedCamera) => {
        let cameraCodes = getCameraCode(mode);
        let tmp = [];
        let finded = false;
        if (updatedCheckpoint.cameraConfiguration && updatedCheckpoint.cameraConfiguration.length > 0) {
            for (let i = 0; i < updatedCheckpoint.cameraConfiguration.length; i++) {
                if (cameraCodes != null && cameraCodes.length > 0 && updatedCheckpoint.cameraConfiguration[i].cameraType === cameraCodes[camIndex]) {
                    tmp.push(updatedCamera);
                    finded = true;
                } else {
                    tmp.push({ ...updatedCheckpoint.cameraConfiguration[i] });
                }
            }
        }
        if (!finded) {
            tmp.push(updatedCamera);
        }
        setUpdatedCheckpoint({ ...updatedCheckpoint, cameraConfiguration: tmp });
    }

    const checkpointPersisted = () => updatedCheckpoint.checkpointId !== undefined && updatedCheckpoint.checkpointId !== null;

    const setActiveStepHandle = (mode) => () => {
        if (mode === STEP_ONE || checkpointPersisted()) {
            setMode(mode);
            setUpdatedCheckpoint({ ...updatedCheckpoint });
        }
    }

    const getTitleClassName = (titleMode) => {
        if (!checkpointPersisted() && titleMode !== STEP_ONE) {
            return classes.titleTextDisabled;
        } else if (titleMode === mode) {
            return classes.titleText;
        } else {
            return classes.titleText2;
        }
    }

    const BookmarkButton = ({ stepName, stepId, canExecute = true }) => (
        <Grid item xs={1.7} className={getTitleClassName(stepId)}>
            <div onClick={canExecute ? setActiveStepHandle(stepId) : () => { }}>{t(stepName)}</div>
        </Grid>
    );

    return (
        <Dialog
            className={classes.container}
            open={checkpointConfigModel.open}
            onClose={handleClose}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle>
                <div className={classes.title}>
                    <Grid container >
                        <BookmarkButton stepName={'BASIC_SETUP'} stepId={STEP_ONE} />
                        <BookmarkButton stepName={'FRONT_CAMERA'} stepId={STEP_TWO} />
                        <BookmarkButton stepName={'BACK_CAMERA_PASSENGER_CAR'} stepId={STEP_THREE} />
                        <BookmarkButton stepName={'BACK_CAMERA_TRACK'} stepId={STEP_FOUR} />
                        <BookmarkButton stepName={'LEFT_SIDE_CAMERA'} stepId={STEP_FIVE} />
                        <BookmarkButton stepName={'RIGHT_SIDE_CAMERA'} stepId={STEP_SIX} />
                    </Grid>
                </div>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error} />

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    {mode === STEP_ONE &&
                        <BasicSetting
                            userUseCases={userUseCases}
                            useCases={useCases}
                            handleChange={handleChange}
                            updatedCheckpoint={updatedCheckpoint}
                            setUpdatedCheckpoint={setUpdatedCheckpoint}
                            error={errorStep1}
                            readOnly={readOnly}
                        />
                    }
                    {mode !== STEP_ONE && mode !== STEP_SEVEN &&
                        <CameraSetting
                            handleChange={handleChange}
                            handleCameraChange={handleCameraChange}
                            handleCameraFullChange={handleCameraFullChange}
                            updatedCheckpoint={updatedCheckpoint}
                            error={errorStep1}
                            readOnly={readOnly}
                            handleCameraTest={handleCameraTest}
                            mode={mode}
                        />
                    }
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                        {!readOnly &&
                            <ActionButton
                                disabled={saving}
                                action="save" handleClick={() => {
                                    const ret = isValid();
                                    if (ret) {
                                        setSaving(true);
                                        saveCheckpoint(updatedCheckpoint);
                                    }
                                }}
                                className={classes.button2}
                            />
                        }
                        {saving &&
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

Checkpoint.propTypes = {};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Checkpoint);


