import checkpointService from '../../services/checkpoint.service';
import types from '../actionTypes';


export const createCheckpoint = (checkpoint) => (dispatch, getState) => {
    if (getState().checkpointsData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_CREATE_REQUEST,
    });

    return checkpointService.createCheckpoint(checkpoint)
        .then((checkpoint) => {
            dispatch({
                type: types.CHECKPOINT_CREATE_SUCCESS,
                payload: {checkpoint: checkpoint},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateCheckpoint = (checkpoint) => (dispatch, getState) => {
    if (getState().checkpointsData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_UPDATE_REQUEST,
    });

    return checkpointService.updateCheckpoint(checkpoint)
        .then((checkpoint) => {
            dispatch({
                type: types.CHECKPOINT_UPDATE_SUCCESS,
                payload: {checkpoint: checkpoint},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateCheckpointState = (checkpointId, active) => (dispatch, getState) => {
    if (getState().checkpointsData.isStateUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_STATE_REQUEST,
    });

    return checkpointService.updateState(checkpointId, active)
        .then((checkpoint) => {
            dispatch({
                type: types.CHECKPOINT_STATE_SUCCESS,
                payload: {checkpoint: checkpoint},
            });
            return checkpoint;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_STATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllCheckpoints = () => (dispatch, getState) => {
    if (getState().checkpointsData.isFetchingAllCenters) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_FETCH_REQUEST,
    });

    return checkpointService.getAllCheckpoints()
        .then((checkpoints) => {
            dispatch({
                type: types.CHECKPOINT_FETCH_SUCCESS,
                payload: { checkpoints: checkpoints },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const addSupervision = (userId, checkpointId) => (dispatch, getState) => {
    if (getState().checkpointsData.isSupervisionUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_SUPERVISION_REQUEST,
    });

    return checkpointService.addSupervision(userId, checkpointId)
        .then(() => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISION_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISION_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const removeSupervision = (userId, checkpointId) => (dispatch, getState) => {
    if (getState().checkpointsData.isSupervisionUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_SUPERVISION_REQUEST,
    });

    return checkpointService.removeSupervision(userId, checkpointId)
        .then(() => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISION_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISION_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const fetchSupervisors = (checkpointId) => (dispatch, getState) => {
    if (getState().checkpointsData.isFetchingSupervisors) {
        return Promise.reject();
    }

    dispatch({
        type: types.CHECKPOINT_SUPERVISORS_REQUEST,
    });

    return checkpointService.getCheckpointSupervisors(checkpointId)
        .then((supervisors) => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISORS_SUCCESS,
                payload: {checkpointId: checkpointId, supervisors: supervisors},
            });
            return supervisors;
        })
        .catch((error) => {
            dispatch({
                type: types.CHECKPOINT_SUPERVISORS_FAIL,
                payload: {error},
            });

            throw error;
        });
}
