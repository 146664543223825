import React from "react";
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../img/logo_hopi_cz_invert.svg';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SecurityIcon from '@mui/icons-material/Security';

export default function LeftDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const {t} = useTranslation();

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{paddingLeft: '15px', paddingRight: '15px'}}>
        <ListItem button key='administration' style={{paddingBottom: '20px', paddingTop: '20px'}}>
          <img src={logo} width={90}/>
          <ArrowBackIosIcon style={{marginLeft: 'auto', fontSize: '16px'}}/>
        </ListItem>
        <Link to={`/${process.env.REACT_APP_PATH}/administration`} style={{textDecoration: 'none'}}>
          <ListItem button key='administration' style={{paddingBottom: '20px'}}>
            <ListItemIcon>
              <SupervisorAccountIcon style={{color: 'black', fontSize: '22px'}}/> <div style={{paddingLeft: '10px', fontSize:'18px', color:'black'}}>{t('administration')}</div>
            </ListItemIcon>
          </ListItem>
        </Link>
        <Link to={`/${process.env.REACT_APP_PATH}/checkpoint`} style={{textDecoration: 'none'}}>
          <ListItem button key='checkpoint' style={{paddingBottom: '20px'}}> 
            <ListItemIcon>
              <SecurityIcon style={{color: 'black', fontSize: '22px'}}/> <div style={{paddingLeft: '10px', fontSize:'18px', color:'black'}}>{t('checkpoint')}</div>
            </ListItemIcon>
          </ListItem>
        </Link>
        <Link to={`/${process.env.REACT_APP_PATH}/checkpointhistory`} style={{textDecoration: 'none'}}>
          <ListItem button key='checkpointhistory'>
            <ListItemIcon>
              <SecurityIcon style={{color: 'black', fontSize: '22px'}}/> <div style={{paddingLeft: '10px', fontSize:'18px', color:'black'}}>{t('checkpointhistory')}</div>
            </ListItemIcon>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon onClick={toggleDrawer(anchor, true)}/>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
