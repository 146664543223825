import React, { useEffect, useState } from "react";
import { Loading } from '../../components/elements';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import store from '../../redux/store';
import { initializeSseSecurityCheckES } from '../../services/securityCheck.sse';
import { Grid, Typography } from "@mui/material";
import { ActionButton } from "../../components/common";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '850px',
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
    checkpointCard: {
        width: 300,
        height: 185,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: theme.palette.background.gray,
        // cursor: 'pointer',
        borderRadius: '15px'
    },
    previewImage: {
        width: '200px'
    },
    detailButton: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 16,
        backgroundColor: theme.palette.background.gray,
        borderRadius: '15px',
        color: 'black'
    },
    activeIndicatorContainer: {
        position: 'relative',
        top: -80,
        right: -20,
    },
    activeIndicatorOverflow: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    activeIndicator: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 10,
        right: 10,
    },
}));

const CheckPoint = (props) => {
    const {
        user,
        userUseCases,
        sseEvent
    } = props;

    const classes = useStyles();
    const [token, setToken] = useState(null);

    const getToken = () => {
        const kcToken = localStorage.getItem('kc_token');
        setToken(kcToken);
    };

    useEffect(() => {
        getToken();
    });

    useEffect(() => {
        if (token) {
            initializeSseSecurityCheckES(token, store, setToken);
        }
    }, [token]);

    if (!user) {
        return (
            <Loading />
        );
    }

    const handleDetail = (item) => {
        console.log('handleDetail', item);
    }

    return (
        <div className={classes.container}>
            <PerfectScrollbar
                options={{
                    suppressScrollX: true,
                    minScrollbarLength: 50,
                }}
            >
                {sseEvent && sseEvent.checkpoints ?
                    sseEvent.checkpoints.map((item, index) => (
                        <div key={item.checkpointId}>
                            <Grid container columnSpacing={1} rowSpacing={2} alignItems="center" justifyContent="center" >
                                <Grid item sm={2} >
                                    <div className={classes.checkpointCard}>
                                        <div className={classes.activeIndicatorContainer} >
                                            {item.securityCheckActive &&
                                                <div className={classes.activeIndicatorOverflow} >
                                                    <div className={classes.activeIndicator} />
                                                </div>
                                            }
                                        </div>
                                        <Typography variant='h3' >
                                            {item.distributionCenterName}
                                        </Typography>

                                        <Typography variant='h2' >
                                            {item.name}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={8}>
                                    <Grid container>
                                        <Grid item sm={2.4} >
                                            <img src={'data:image/jpg;base64,' + item.cameraFront}
                                                className={classes.previewImage}
                                            />
                                        </Grid>
                                        <Grid item sm={2.4}>
                                            <img src={'data:image/jpg;base64,' + item.cameraMid}
                                                className={classes.previewImage}
                                            />
                                        </Grid>
                                        <Grid item sm={2.4}>
                                            <img src={'data:image/jpg;base64,' + item.cameraBack}
                                                className={classes.previewImage}
                                            />
                                        </Grid>
                                        <Grid item sm={2.4}>
                                            <img src={'data:image/jpg;base64,' + item.cameraLeft}
                                                className={classes.previewImage}
                                            />
                                        </Grid>
                                        <Grid item sm={2.4}>
                                            <img src={'data:image/jpg;base64,' + item.cameraRight}
                                                className={classes.previewImage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={2} >
                                    {item.securityCheckActive &&
                                        <Link to={`/${process.env.REACT_APP_PATH}/checkpoint/securitycheck/${item.checkpointId}`}>
                                            <ActionButton
                                                action='detail'
                                                className={classes.detailButton}
                                                handleClick={() => { handleDetail(item) }}
                                            />
                                        </Link>}
                                </Grid>
                            </Grid>
                        </div>
                    ))
                    :
                    <Loading />
                }
            </PerfectScrollbar>
        </div>
    );

}

CheckPoint.propTypes = {
    user: PropTypes.object,
};

CheckPoint.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    sseEvent: store.securityCheckData.securityCheck
});

export default connect(
    mapStateToProps,
)(CheckPoint);