import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import { isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Add from "@mui/icons-material/Add";
import { UC0401 } from '../../../utils/constants'
import DefaultToolBar from '../../../components/common/DefaultTopToolbar';
import TableGridIcon from '../../../components/common/TableGridIcon';



const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(1),
        marginTop: '-5px',
        // padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleCheckpointUpdate,
        selectedCheckpointName,
        handleSelectedCheckpointName,
        setTable,
        table
    } = props;

    const { t } = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    return (
        <DefaultToolBar addButton={
            isComponentVisibleForUser(UC0401, userUseCases) &&
            <IconButton
                className={classes.addButton}
                edge="start"
                color="inherit"
                aria-label="add distribution center"
                onClick={() => {
                    handleCheckpointUpdate({ distributionCenterId: 0, lat: '50.05193', lng: '14.42686' }, true);
                }}
                disabled={!isComponentEnabledForUser(UC0401, userUseCases)}
                size="large">
                <Add className={classes.addIcon} />
            </IconButton>
        }>
            <Grid item sm={3}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="search"
                    label={t('CHECKPOINT_NAME')}
                    fullWidth
                    value={selectedCheckpointName || ''}
                    onChange={handleSelectedCheckpointName}
                    InputProps={{ sx: { borderRadius: 30 } }}
                />
            </Grid>
            <Grid item sm={8} />
            <Grid item sm={1} >
                <TableGridIcon table={table} setTable={setTable} />
            </Grid>
        </DefaultToolBar>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
