import httpService from './http.service';


const createDistributionCenter = (center) => httpService
    .post(`/distributioncenter`, center)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateDistributionCenter = (center) => httpService
    .put(`/distributioncenter`, center)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateState = (centerId, active) => httpService
    .put(`/distributioncenter/${centerId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getAllDistributionCenters = () => httpService
    .get('/distributioncenter/all')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));



export default {
    getAllDistributionCenters, createDistributionCenter, updateDistributionCenter, updateState
};
