import {EventSourcePolyfill} from 'event-source-polyfill';

import types from '../redux/actionTypes';
import {updateToken} from './keycloak.service';


let sseSecurityCheck;

const baseURL = process.env.REACT_APP_API;

const startSseSecurityCheckES = (token, type, checkpointId) => {
    sseSecurityCheck = new EventSourcePolyfill(
        type?`${baseURL}/sse/sucuritycheck?type=${type}&checkpointId=${checkpointId}`:`${baseURL}/sse/sucuritycheck`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
};

const onerrorSseSecurityCheckES = (token, store, setToken) => (e) => {
    sseSecurityCheck.close();
    updateToken(setToken, () => {
        initializeSseSecurityCheckES(token, store, setToken);
    }, -1);
};

const messageSseSecurityCheckES = (store) => (eventResponse) => {
    const data = JSON.parse(eventResponse.data);
    console.log('sse sucuritycheck data', data);
    store.dispatch({
        type: types.SECURITY_CHECK_SSE_FETCH_SUCCESS,
        payload: {
            securityCheckSse: data != null ? data : null,
        },
    });
};

export const closeSseSecurityCheckES = () => {
    if (sseSecurityCheck) {
        sseSecurityCheck.close();
    }
};

export const initializeSseSecurityCheckES = (token, store, setToken, type, checkpointId) => {
    try {
        sseSecurityCheck.close();
    } catch (err) {
    }
    
    startSseSecurityCheckES(token, type, checkpointId);
    sseSecurityCheck.addEventListener('message', messageSseSecurityCheckES(store));
    sseSecurityCheck.addEventListener('error', onerrorSseSecurityCheckES(token, store, setToken));
};
