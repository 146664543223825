import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxIconOutlined from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

const getColor = (theme, color) => {
    switch (color) {
        case 'dark':
            return {
                base: theme.palette.base[800],
                hover: '#35353E',
                active: '#000000',
                disabled: '#828282',
            };
        case 'green':
            return {
                base: theme.palette.primary.main,
                hover: 'rgba(80,169,51, 0.75)',
                active: 'rgba(80,169,51, 1)',
                disabled: 'rgba(80,169,51, 0.5)',
            };
        case 'yellow':
            return {
                base: '#F5894A',
                hover: '#FFAD6E',
                active: '#F57A38',
                disabled: '#FEB0A7',
            };
        case 'red':
            return {
                base: theme.palette.primary.red,
                hover: '#FF5D4B',
                active: '#FC3822',
                disabled: '#FEB0A7',
            };
        case 'grey':
            return {
                base: theme.palette.base[100],
                hover: '#F6F6F6',
                active: '#EBEBEB',
                disabled: '#EEEEEE',
            };
        case 'white':
            return {
                base: theme.palette.base.white,
                hover: '#F6F6F6',
                active: '#FAF8FF',
                disabled: '#EEEEEE',
            };
        case 'ping':
            return {
                base: '#e71c62',
                hover: '#ff0054',
                active: '#e71c62',
                disabled: '#FEB0A7',
            };
        case 'blue':
        default:
            return {
                base: theme.palette.secondary.main,
                hover: '#769CF9',
                active: '#5F74FB',
                disabled: '#A8C1FF',
            };
    }
};

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: (props) => props.width,
        height: (props) => props.height,
        paddingTop: (props) => props.paddingTop,
        paddingLeft: (props) => props.paddingLeft,
        backgroundColor: (props) => getColor(theme, props.color).base,
        borderRadius: 2,
        color: (props) => (props.color === 'grey' || props.color === 'white' ? '#60636B' : 'white'),

        '&:hover': {
            backgroundColor: (props) => getColor(theme, props.color).hover,
        },

        '&:active': {
            backgroundColor: (props) => getColor(theme, props.color).active,
        },

        '&:disabled': {
            backgroundColor: (props) => getColor(theme, props.color).disabled,
            color: (props) => (props.color === 'grey' ? '#D4D5D9' : 'white'),
        },
    },
    buttonIcon: {
        display: 'inline-block',
        alignItems: 'center',
        marginRight: theme.spacing(0.5),
        cursor: 'pointer',
    },
    buttonIconInner: {
        marginBottom: '-6px',
    },
    buttonText: {
        display: 'inline-block',
        paddingBottom: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        cursor: 'pointer',
    }
}));

function CustomCheckBox({
                          className,
                          width,
                          height,
                          color,
                          disabled,
                          onClick,
                          iconNumber,
                          paddingTop,
                          paddingLeft,
                          text,
                          selectAll = false,
                      }) {
    const classes = useStyles({width, height, color, paddingTop, paddingLeft});

    return (
        <div className={classnames(classes.button, className)}
        >
            <div className={classes.buttonText} onClick={onClick}>{text}</div>
            <div className={classes.buttonIcon} onClick={onClick}>
            {!iconNumber && 
                <CheckBoxOutlineBlankIcon className={selectAll ? classes.buttonIconInner : ""} disabled={disabled}/>
            }
            {iconNumber===1 &&
                <CheckBoxIconOutlined className={selectAll ? classes.buttonIconInner : ""} disabled={disabled}/>
            }
            {iconNumber===2 &&
                <CheckBoxIcon className={selectAll ? classes.buttonIconInner : ""} disabled={disabled}/>
            }
            {iconNumber===3 &&
                <IndeterminateCheckBoxOutlinedIcon className={selectAll ? classes.buttonIconInner : ""} disabled={disabled}/>
            }
            </div>
        </div>
    );
}

CustomCheckBox.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.array,
    onClick: PropTypes.func,
};

CustomCheckBox.defaultProps = {
    className: '',
    width: '20',
    height: '20',
    color: 'blue',
    disabled: false,
    icon: [],
    onClick: undefined,
};

export default CustomCheckBox;
