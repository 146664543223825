import { Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionButton } from '../../common';
import { useEffect, useState } from 'react';
import {
    CAMERA_TYPE_BACK_LP,
    CAMERA_TYPE_BACK_STREAM,
    CAMERA_TYPE_FRONT_LP,
    CAMERA_TYPE_FRONT_STREAM,
    CAMERA_TYPE_MID_LP,
    CAMERA_TYPE_MID_STREAM,
    CAMERA_TYPE_SIDE_STREAM_LEFT,
    CAMERA_TYPE_SIDE_STREAM_RIGHT,
    STEP_FIVE,
    STEP_FOUR,
    STEP_SIX,
    STEP_THREE,
    STEP_TWO
} from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
    testButton: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 16,
        backgroundColor: theme.palette.background.green,
        borderRadius: '15px',
        color: 'black',
        "&:disabled": {
            backgroundColor: theme.palette.background.darkGrey,
        }
    },
    description: {
        color: 'black',
        fontSize: '16px',
        fontWeight: 600,
        marginLeft: '20px',
    },
    descriptionNoPreview: {
        color: 'black',
        fontSize: '16px',
        fontWeight: 600,
        marginLeft: '20px',
        height: '300px',
    },
    previewImage: {
        height: '300px',
    },
    container: {
        position: 'relative',
        display: 'inline-block',
    },
    line: {
        position: 'absolute',
        top: '50%',
        left: '0',
        width: '100%',
        height: '5px',
        backgroundColor: 'red',
        cursor: 'move',
    },
}));

const ImageWithStopLine = ({ src, className, handleChange, updatedCheckpoint }) => {
    const classes = useStyles();
    const [linePosition, setLinePosition] = useState(
        updatedCheckpoint?.line?.startY ?
            updatedCheckpoint?.line?.startY * 100 :
            70);

    const [isDragging, setIsDragging] = useState(false);

    const updateLine = (newLinePosition) => {
        handleChange('line')({
            startX: 0,
            endX: 1.0,
            startY: newLinePosition / 100.0,
            endY: newLinePosition / 100.0
        });
    }

    useEffect(() => {
        if (updatedCheckpoint?.line?.startY) {
            updateLine(linePosition)
        }
    }, []);

    const handlePointerDown = (e) => {
        setIsDragging(true);
    };

    const handlePointerUp = (e) => {
        setIsDragging(false);
    };

    const handlePointerMove = (e) => {
        if (isDragging) {
            let newLinePosition = (e.clientY - e.currentTarget.getBoundingClientRect().top) / 0.8;
            newLinePosition = newLinePosition / 3;
            newLinePosition = Math.max(10, Math.min(90, newLinePosition));
            setLinePosition(newLinePosition);
            updateLine(newLinePosition);
        }
    };

    useEffect(() => {
        window.addEventListener('pointerup', handlePointerUp);

        return () => {
            window.removeEventListener('pointerup', handlePointerUp);
        }
    }, []);

    return (
        <div
            className={`${classes.container}`}
            onPointerMove={handlePointerMove}>
            <img src={src} className={className} alt="Preview" />
            <div
                className={classes.line}
                style={{ top: `${linePosition}%` }}
                onPointerDown={handlePointerDown}
            />
        </div>
    );
};

function CameraSetting(props) {
    const classes = useStyles();

    const {
        user,
        updatedCheckpoint,
        handleChange,
        handleCameraChange,
        handleCameraFullChange,
        error,
        readOnly,
        handleCameraTest,
        mode,
        testIsLoading,
    } = props;

    const { t } = useTranslation();

    const [lpnCamera, setLpnCamera] = useState({});
    const [streamCamera, setStreamCamera] = useState({});

    useEffect(() => {
        if (updatedCheckpoint && updatedCheckpoint.cameraConfiguration && updatedCheckpoint.cameraConfiguration.length > 0) {
            let camera1 = {}, camera2 = {};
            for (let i = 0; i < updatedCheckpoint.cameraConfiguration.length; i++) {
                if (
                    (mode === STEP_TWO && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_FRONT_LP) ||
                    (mode === STEP_THREE && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_MID_LP) ||
                    (mode === STEP_FOUR && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_BACK_LP)
                ) {
                    camera1 = updatedCheckpoint.cameraConfiguration[i];
                }
                if (
                    (mode === STEP_TWO && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_FRONT_STREAM) ||
                    (mode === STEP_THREE && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_MID_STREAM) ||
                    (mode === STEP_FOUR && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_BACK_STREAM) ||
                    (mode === STEP_FIVE && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_SIDE_STREAM_LEFT) ||
                    (mode === STEP_SIX && updatedCheckpoint.cameraConfiguration[i].cameraType === CAMERA_TYPE_SIDE_STREAM_RIGHT)
                ) {
                    camera2 = updatedCheckpoint.cameraConfiguration[i];
                }
            }
            setLpnCamera(camera1);
            setStreamCamera(camera2);
        }
    }, [updatedCheckpoint]);

    return (
        <Grid container columnSpacing={3}>
            <Grid item sm={5}>
                <Grid container rowSpacing={2} >
                    {mode !== STEP_FIVE && mode !== STEP_SIX &&
                        <>
                            <Grid item sm={12} >
                                <Typography variant="h6" className={classes.description}>
                                    {t('LPN')}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    helperText={error && error.address
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="lpnCameraAddress"
                                    value={lpnCamera.address || ''}
                                    onChange={handleCameraChange('address', 0)}
                                    label={t('CAMERA_ADDRESS')}
                                    InputProps={{ sx: { borderRadius: 30 } }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="outlined"
                                    helperText={error && error.url
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="lpnUrlStream"
                                    value={lpnCamera.url || ''}
                                    onChange={handleCameraChange('url', 0)}
                                    label={t('URL_STREAM')}
                                    InputProps={{ sx: { borderRadius: 30 } }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="outlined"
                                    helperText={error && error.username
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="lpnUser"
                                    value={lpnCamera.username || ''}
                                    onChange={handleCameraChange('username', 0)}
                                    label={t('USER')}
                                    InputProps={{ sx: { borderRadius: 30 } }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="outlined"
                                    helperText={error && error.password
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="lpnPassword"
                                    value={lpnCamera.password || ''}
                                    onChange={handleCameraChange('password', 0)}
                                    label={t('PASSWORD')}
                                    InputProps={{ sx: { borderRadius: 30 } }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    id="lpnApiKey"
                                    value={lpnCamera.apiKey || ''}
                                    label={t('API_KEY')}
                                    InputProps={{ sx: { borderRadius: 30 } }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <ActionButton
                                    action={'connectionTest'}
                                    className={classes.testButton}
                                    handleClick={() => { handleCameraTest(lpnCamera, handleCameraFullChange(0)) }}
                                    disabled={readOnly || testIsLoading}
                                />
                            </Grid>
                        </>
                    }
                    <>
                        <Grid item sm={12} >
                            <Typography variant="h6" className={classes.description}>
                                {t('ANALYTICS')}
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                variant="outlined"
                                helperText={error && error.address
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="analyticsCameraAddress"
                                value={streamCamera.address || ''}
                                onChange={handleCameraChange('address', 1)}
                                label={t('CAMERA_ADDRESS')}
                                InputProps={{ sx: { borderRadius: 30 } }}
                                size='small'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                variant="outlined"
                                helperText={error && error.url
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="analyticsUrlStream"
                                value={streamCamera.url || ''}
                                onChange={handleCameraChange('url', 1)}
                                label={t('URL_STREAM')}
                                InputProps={{ sx: { borderRadius: 30 } }}
                                size='small'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                variant="outlined"
                                helperText={error && error.analyticsUser
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="analyticsUser"
                                value={streamCamera.username || ''}
                                onChange={handleCameraChange('username', 1)}
                                label={t('USER')}
                                InputProps={{ sx: { borderRadius: 30 } }}
                                size='small'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                variant="outlined"
                                helperText={error && error.analyticsPassword
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="analyticsPassword"
                                value={streamCamera.password || ''}
                                onChange={handleCameraChange('password', 1)}
                                label={t('PASSWORD')}
                                InputProps={{ sx: { borderRadius: 30 } }}
                                size='small'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <ActionButton
                                action={'connectionTest'}
                                className={classes.testButton}
                                handleClick={() => { handleCameraTest(streamCamera, handleCameraFullChange(1)) }}
                                disabled={readOnly || testIsLoading}
                            />
                        </Grid>
                    </>
                </Grid>
            </Grid>
            <Grid item sm={7}>
                <Grid container rowSpacing={2} direction="column" alignItems="center" justifyContent='center' sx={{ minHeight: '720px' }}>
                    {mode !== STEP_FIVE && mode !== STEP_SIX &&
                        <Grid item xs={6}>
                            {lpnCamera && lpnCamera.previewImage &&
                                <img src={'data:image/jpg;base64,' + lpnCamera.previewImage}
                                    className={classes.previewImage}
                                />
                            }
                            {(!lpnCamera || !lpnCamera.previewImage) &&
                                <Typography variant="h6" className={classes.descriptionNoPreview}>
                                    {t('NO_SCREEN_LPN')}
                                </Typography>
                            }
                        </Grid>
                    }
                    <Grid item xs={6}>
                        {streamCamera && streamCamera.previewImage && streamCamera.cameraType === CAMERA_TYPE_FRONT_STREAM &&
                            <ImageWithStopLine
                                src={'data:image/jpg;base64,' + streamCamera.previewImage}
                                className={classes.previewImage}
                                handleChange={handleChange}
                                updatedCheckpoint={updatedCheckpoint}
                            />
                        }
                        {streamCamera && streamCamera.previewImage && streamCamera.cameraType !== CAMERA_TYPE_FRONT_STREAM &&
                            <img
                                src={'data:image/jpg;base64,' + streamCamera.previewImage}
                                className={classes.previewImage}
                            />
                        }
                        {(!streamCamera || !streamCamera.previewImage) &&
                            <Typography variant="h6" className={classes.descriptionNoPreview}>
                                {t('NO_SCREEN_STREAM')}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

CameraSetting.propTypes = {};
CameraSetting.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
    testIsLoading: store.cameraConfigurationData.isTesting,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CameraSetting);
