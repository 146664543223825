import React from 'react';
import { Tooltip } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';

const ActivityState = ({ active }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    if (!!active)
        return <Tooltip title={t('USER_ACTIVE')}><CheckCircleRoundedIcon color="primary" style={{ fontSize: 35 }} /></Tooltip>
    else
        return <Tooltip title={t('USER_INACTIVE')}><BedtimeIcon style={{
            fontSize: 30,
            color: "white",
            backgroundColor: palette.background.darkGrey,
            borderRadius: 18,
            padding: 4
        }} /></Tooltip>
}

export default ActivityState;
