export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const DATE_FORMAT = "YYYY-MM-DD";

export const UC0000 = "UC0000"; // Seznam číselníku
export const UC0001 = "UC0001"; // Odhlásit
export const UC0002 = "UC0002"; // Menu administrace
export const UC0003 = "UC0003"; // Menu Admin
export const UC0004 = "UC0004"; // Seznam UC
export const UC0005 = "UC0005"; // Fulltextové vyhledávání
export const UC0100 = "UC0100"; // Rozcestník administrace
export const UC0101 = "UC0101"; // Seznam uživatelů
export const UC0102 = "UC0102"; // Založení uživatele
export const UC0103 = "UC0103"; // Úprava uživatele
export const UC0104 = "UC0104"; // Detail uživatele
export const UC0105 = "UC0105"; // Přidání uživatele na roli
export const UC0106 = "UC0106"; // Odstranění uživatele z role
export const UC0107 = "UC0107"; // Aktivace/deaktivace uživatele
export const UC0111 = "UC0111"; // Seznam rolí
export const UC0112 = "UC0112"; // Založení role
export const UC0113 = "UC0113"; // Detail role
export const UC0114 = "UC0114"; // Aktivace/deaktivace role
export const UC0115 = "UC0115"; // Úprava role
export const UC0301 = "UC0301"; // Analýza otevření nákladního prostoru/zavazadlového prostoru
export const UC0302 = "UC0302"; // Notifikace obsluze
export const UC0303 = "UC0303"; // Odeslání výsledků kotnroly do QTir
export const UC0304 = "UC0304"; // Pořízení fotodokumentace boku návěsu
export const UC0305 = "UC0305"; // Pořízení fotodokumentace v případě plomby
export const UC0306 = "UC0306"; // Pozicování vozidla
export const UC0307 = "UC0307"; // Získání registrační značky z QTir
export const UC0308 = "UC0308"; // Zobrazení instrukcí na displej řidičům
export const UC0400 = "UC0400"; // Seznam distribučních center
export const UC0401 = "UC0401"; // Založení distribučního centra
export const UC0402 = "UC0402"; // Úprava distribučního centra
export const UC0403 = "UC0403"; // Deaktivace distribučního centra
export const UC0404 = "UC0404"; // Deatail distribučního centra
export const UC0410 = "UC0410"; // Seznam kontrolních stanovišť
export const UC0411 = "UC0411"; // Založení kontrolního stanoviště
export const UC0412 = "UC0412"; // Nastavení kontrolního stanoviště pro lokalitu
export const UC0413 = "UC0413"; // Nastavení virtuálního kontrolního bodu
export const UC0415 = "UC0415"; // Přiřazení uživatelů Dohled na kontrolní stanoviště
export const UC0421 = "UC0421"; // Zobrazení detailu konkrétní transakce
export const UC0422 = "UC0422"; // Automatické spuštění kontroly
export const UC0423 = "UC0423"; // Ruční spuštění kontroly
export const UC0424 = "UC0424"; // Ruční ukončení kontroly
export const UC0425 = "UC0425"; // Zobrazení aktuálně probíhající kontroly
export const UC0426 = "UC0426"; // Zobrazení historie kontrol
export const UC0427 = "UC0427"; // Security check a dodatečná registrace plomby
export const UC0428 = "UC0428"; // Parametry Security Check
export const UC0500 = "UC0500"; // Zobrazení notifikace

export const STEP_ONE = 'STEP_1';
export const STEP_TWO = 'STEP_2';
export const STEP_THREE = 'STEP_3';
export const STEP_FOUR = 'STEP_4';
export const STEP_FIVE = 'STEP_5';
export const STEP_SIX = 'STEP_6';
export const STEP_SEVEN = 'STEP_7';

export const CAMERA_TYPE_FRONT_STREAM = "CAM_FRONT_STREAM";
export const CAMERA_TYPE_MID_STREAM = "CAM_MID_STREAM";
export const CAMERA_TYPE_BACK_STREAM = "CAM_BACK_STREAM";
export const CAMERA_TYPE_FRONT_LP = "CAM_FRONT_LP";
export const CAMERA_TYPE_MID_LP = "CAM_MID_LP";
export const CAMERA_TYPE_BACK_LP = "CAM_BACK_LP";
export const CAMERA_TYPE_SIDE_STREAM_LEFT = "CAM_SIDE_STREAM_LEFT";
export const CAMERA_TYPE_SIDE_STREAM_RIGHT = "CAM_SIDE_STREAM_RIGHT";

export const EMITTER_TYPE_CHECKPOINT = "ET_CHECKPOINT";
export const EMITTER_TYPE_SECURITY_CHECK = "ET_SECURITY_CHECK";