import React from "react";
import {Loading} from '../../components/elements';
import PerfectScrollbar from "react-perfect-scrollbar";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AdministrationList from "./AdministartionList";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '850px',
        marginTop: theme.spacing(2),
        borderRadius: '4px',
      },
}));

const Adminstration = (props) => {
    const {
        user,
        userUseCases
    } = props;

    const classes = useStyles();

    if (!user) {
        return (
            <Loading/>
        );
    }

    return (
        <div className={classes.container}>
            <PerfectScrollbar
                options={{
                    suppressScrollX: true,
                    minScrollbarLength: 50,
                }}
            >
                <AdministrationList
                    user={user}
                    userUseCases={userUseCases}
                />
            </PerfectScrollbar>
        </div>
    );

}

Adminstration.propTypes = {
    user: PropTypes.object,
};

Adminstration.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
});

export default connect(
    mapStateToProps,
)(Adminstration);