import types from '../actionTypes';

const INITIAL_STATE = {
  securityCheck: [],
  errors: '',
  isFinishing: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SECURITY_CHECK_SSE_FETCH_SUCCESS:
      return {
        ...state,
        securityCheck: action.payload.securityCheckSse,
      };

    case types.SECURITY_CHECK_FINISH_REQUEST:
      return {
        ...state,
        isFinishing: true,
      };

    case types.SECURITY_CHECK_FINISH_SUCCESS:
      return {
        ...state,
        isFinishing: false,
      };
    
    case types.SECURITY_CHECK_FINISH_FAIL:
      return {
        ...state,
        isFinishing: false,
        errors: action.payload.error,
      };
      
    default:
      return state;
  }
};
