import React from 'react';
import TocIcon from '@mui/icons-material/Toc';
import GridViewIcon from '@mui/icons-material/GridView';


const TableGridIcon = ({table, setTable}) => {
    if (!table) {
        return <TocIcon
            onClick={() => { setTable(!table); }}
            style={{ width: '35px', height: '35px', marginTop: '4px' }}
        />;
    } else {
        return <GridViewIcon
            onClick={() => { setTable(!table); }}
            style={{ width: '35px', height: '35px', marginTop: '4px' }}
        />;
    }
}

export default TableGridIcon;
