import React from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@mui/material/Box';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {ArrowUpward, ChevronRight} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import MaterialTable from '@material-table/core';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import CustomCheckBox from '../../elements/CustomCheckBox2';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        
        alignItems: 'flex-start',
        paddingTop: '0px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2, 2, 2, 2),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.9)',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '7px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    roleBox1: {
        padding: theme.spacing(2),
        marginRight: '15px',
        border: '1px solid #000000', 
        borderRadius: '15px',
    },
    roleBox2: {
        
        // marginRight: "auto", 
        border: '1px solid #000000', 
        borderRadius: '15px',
    }
}));

function UserUpdate(props) {
    const classes = useStyles();

    const {
        roles,
        rowClickHandel,
        headerClickHandel,
        headerChecked,
        handleChange,
        updatedUser,
        error,
        readOnly,
        isNew,
    } = props;

    const {t} = useTranslation();
    const {palette} = useTheme();
    
    return (
        <div className={classes.formFields}>
            <Grid container rowSpacing={2} columnSpacing={5}>
                <Grid item sm={5}></Grid>
                <Grid item sm={7}></Grid>

                <Grid item sm={5}>
                    <Typography variant="h6" className={classes.description}>
                        {t('USER_INFO') }
                    </Typography>                            
                </Grid>
                <Grid item sm={7}>
                    <Typography variant="h6" className={classes.description}>
                        {t('CHOOSE_ROLE')}
                    </Typography>
                </Grid>

                <Grid item sm={5}></Grid>
                <Grid item sm={7}></Grid>

                <Grid item sm={5}>
                    <Grid container spacing={1} className={classes.roleBox1}>
                        <Grid item xs sm={12} >
                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <TextField
                                        variant="standard"
                                        style={{height: '60px'}}
                                        autoFocus
                                        helperText={error && error.firstName
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        disabled={readOnly}
                                        id="firstName"
                                        value={updatedUser.firstName || ''}
                                        onChange={handleChange('firstName', false)}
                                        label={t('FIRST_NAME')} 
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        variant="standard"
                                        style={{height: '60px'}}
                                        autoFocus
                                        helperText={error && error.lastName
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="lastName"
                                        value={updatedUser.lastName || ''}
                                        onChange={handleChange('lastName', false)}
                                        label={t('LAST_NAME')} 
                                    />                                    
                                </Grid>
                            </Grid>

                            <TextField
                                variant="standard"
                                style={{height: '60px'}}
                                autoFocus
                                helperText={error && error.userName
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly || !isNew}
                                id="userName"
                                value={updatedUser.userName || ''}
                                onChange={handleChange('userName', false)}
                                label={t('LOGIN')} />
                            <TextField
                                variant="standard"
                                style={{height: '60px'}}
                                autoFocus
                                helperText={error && error.email
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="email"
                                value={updatedUser.email || ''}
                                onChange={handleChange('email', false)}
                                label={t('EMAIL')} />

                            <TextField
                                variant="standard"
                                style={{height: '60px'}}
                                autoFocus
                                helperText={error && error.password
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required={isNew}
                                disabled={readOnly}
                                id="password"
                                value={updatedUser.password || ''}
                                onChange={handleChange('password', false)}
                                label={t('PASSWORD')}
                                type="password" />

                            <FormControl
                                variant="standard"
                                required
                                fullWidth
                                disabled={false}
                                style={{height: '60px'}}>
                                <InputLabel id="active-label">
                                    {t('USER_ACTIVE')}
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    autoWidth
                                    disabled={readOnly}
                                    value={updatedUser.active || ''}
                                    id="active"
                                    onChange={handleChange('active', true)}>
                                    <MenuItem value=''>-</MenuItem>
                                    <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                    <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                </Select>
                                <FormHelperText>  {error && error.active && (
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                )}</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={7}>
                    <Grid container alignItems="stretch" spacing={1} className={classes.roleBox2} >
                        <div className={classes.editorWrapper}>
                        
                            <MaterialTable
                                title=""
                                search="false"
                                icons={{
                                    SortArrow: ArrowUpward,
                                    DetailPanel: ChevronRight,
                                    Search: Search,
                                    ResetSearch: Clear
                                }}
                                data={roles}

                                columns={[
                                    { title: t('ROLE_NAME'), field: 'name' },
                                    { title: t('ROLE_ADMIN'), field: 'admin', render: rowData => (rowData.admin ? t('YES') : t('NO')), },
                                    { title: t('ROLE_ACTIVE'), field: 'active', render: rowData => (rowData.active ? t('YES') : t('NO')), },
                                ]}

                                options={{
                                    showTitle: false, paging:false, showTextRowsSelected:false,
                                    headerStyle: {
                                        position: "sticky",
                                        top: "0",
                                        fontSize: 18,
                                        color: palette.background.darkGrey,
                                    },
                                    maxBodyHeight: "512px",
                                    
                                    toolbarButtonAlignment:"left"
                                }}

                                style={{boxShadow: 'none', border: 'none'}}

                                actions={readOnly?[]:[
                                    {
                                        icon: 'checked',
                                        tooltip: 'checked',
                                        onClick: (event, rowData) => {rowClickHandel(rowData)}
                                    },
                                    {
                                        icon: 'checkAll',
                                        tooltip: 'check all',
                                        onClick: (event, rowData) =>  headerClickHandel(),
                                        isFreeAction: true,
                                    },
                                ]}

                                localization={{
                                    header: {
                                        actions: ''
                                    },
                                    toolbar: {
                                        exportName: 'Export',
                                        searchTooltip: t('SEARCH_INNER'),
                                        searchPlaceholder: t('SEARCH_INNER'),
                                    }
                                }}

                                components={{
                                    Action: props => (
                                        <CustomCheckBox
                                        onClick={(event) => props.action.onClick(event, props.data)}
                                        color={'white'}
                                        iconNumber={props.data.name?props.data.checked:headerChecked}
                                        paddingTop={props.data.name?'0':'8px'}
                                        paddingLeft={props.data.name?'20px':'0'}
                                        width='5'
                                        height='5'
                                        text={props.data.name?'':t('CHECK_ALL')}
                                        selectAll={!props.data.name}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

UserUpdate.propTypes = {};
UserUpdate.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserUpdate);


