import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { ArrowUpward, ChevronRight } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import MaterialTable from '@material-table/core';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import CustomCheckBox from '../../elements/CustomCheckBox2'
import { Tab, Tabs, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '0px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2, 2, 2, 2),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.9)',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '7px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    roleBox1: {
        padding: theme.spacing(2),
        marginRight: '15px',
        border: '1px solid #000000', 
        borderRadius: '15px',
    },
    roleBox2: {
        
        // marginRight: "auto", 
        border: '1px solid #000000', 
        borderRadius: '15px',
    }
}));

function RoleUpdate(props) {
    const classes = useStyles();
    const {palette} = useTheme();

    const {
        useCases,
        rowClickHandel,
        rowSetExpanded,
        headerClickHandel,
        headerChecked,
        handleChange,
        updatedRole,
        error,
        readOnly,
        user,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.formFields}>
            <Grid container rowSpacing={2} columnSpacing={5}>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}></Grid>

                <Grid item sm={3}>
                    <Typography variant="h6" className={classes.description}>
                        {t('ROLE_INFO') }
                    </Typography>                            
                </Grid>
                <Grid item sm={9}>
                    <Typography variant="h6" className={classes.description}>
                        {t('CHOOSE_UC')}
                    </Typography>
                </Grid>

                <Grid item sm={3}></Grid>
                <Grid item sm={9}></Grid>

                <Grid item sm={3}>
                    <Grid container spacing={1} className={classes.roleBox1}>
                        <Grid item xs sm={12} >
                            <TextField
                                variant="standard"
                                style={{ height: '80px' }}
                                autoFocus
                                helperText={error && error.name
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                disabled={readOnly}
                                id="name"
                                value={updatedRole.name || ''}
                                onChange={handleChange('name', false)}
                                label={t('NAME')} />

                            <FormControl variant="standard" fullWidth disabled={false} style={{ height: '80px' }} required>
                                <InputLabel id="parking-label">
                                    {t('ROLE_ACTIVE')}
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    autoWidth
                                    disabled={readOnly}
                                    value={updatedRole.active || ''}
                                    id="active"
                                    onChange={handleChange('active', true)}>
                                    <MenuItem value=''>-</MenuItem>
                                    <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                    <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                </Select>
                                <FormHelperText>  {error && error.active && (
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                )}</FormHelperText>
                            </FormControl>

                            {user.admin &&
                                <FormControl variant="standard" fullWidth disabled={false} style={{ height: '80px' }} required>
                                    <InputLabel id="parking-label">
                                        {t('ADMIN_ROLE')}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedRole.admin || ''}
                                        id="admin"
                                        onChange={handleChange('admin', true)}>
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.admin && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={9}>
                    <Grid container alignItems="stretch" spacing={1} className={classes.roleBox2} >
                        <div className={classes.editorWrapper}>

                            <MaterialTable
                                title=""
                                icons={{
                                    SortArrow: ArrowUpward,
                                    DetailPanel: ChevronRight,
                                    Search: Search,
                                    ResetSearch: Clear
                                }}
                                data={useCases}

                                columns={[
                                    { title: t('MODULE'), field: 'module' },
                                    { title: t('UC_CODE'), field: 'code' },
                                    { title: t('UC_NAME'), field: 'description' },
                                ]}
                                onTreeExpandChange={(row, expanded) => {
                                    if (!!rowSetExpanded) {
                                        rowSetExpanded(row, expanded);
                                    }
                                }}
                                parentChildData={(row, rows) => {
                                    row.tableData.isTreeExpanded = !!row.expanded;
                                    return rows.find(a => a.useCaseId === row.parentId);
                                }}

                                options={{
                                    showTitle: false, paging: false, showTextRowsSelected: false,
                                    headerStyle: {
                                        position: "sticky",
                                        top: "0",
                                        fontSize: 18,
                                        color: palette.background.darkGrey,
                                    },
                                    maxBodyHeight: "512px",
                                    toolbarButtonAlignment: "left",
                                    search: true
                                }}

                                style={{boxShadow: 'none', border: 'none'}}

                                actions={[
                                    {
                                        icon: 'checked',
                                        tooltip: 'checked',
                                        onClick: (event, rowData) => { rowClickHandel(rowData) }
                                    },
                                    {
                                        icon: 'checkAll',
                                        tooltip: 'check all',
                                        onClick: (event, rowData) => headerClickHandel(),
                                        isFreeAction: true,
                                    },
                                ]}

                                localization={{
                                    header: {
                                        actions: ''
                                    },
                                    toolbar: {
                                        exportName: 'Export',
                                        searchTooltip: t('SEARCH_INNER'),
                                        searchPlaceholder: t('SEARCH_INNER'),
                                    }
                                }}

                                components={{
                                    Action: props => (
                                        <CustomCheckBox
                                            onClick={(event) => props.action.onClick(event, props.data)}
                                            color={'white'}
                                            iconNumber={props.data.useCaseId ? props.data.checked : headerChecked}
                                            paddingTop={props.data.useCaseId ? '0' : '8px'}
                                            paddingLeft={props.data.useCaseId ? '20px':'0'}
                                            width='5'
                                            height='5'
                                            text={props.data.useCaseId ? '' : t('CHECK_ALL')}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

RoleUpdate.propTypes = {};
RoleUpdate.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleUpdate);


